import React, { Component } from "react";
import { Heading, Box, Text, Flex, Button, Image } from "rebass";
import { Link } from "react-router-dom";

import { HeroGrid, HeroGridCard } from "./HeroGrid";

import "normalize.css";
import "./index.css";
import globalStyles from "./Global.module.css";

import LogoLight from "./logo1.svg";
import LogoDark from "./logo.png";
import footerFacebook from "./footer-facebook.png";
import footerTwitter from "./footer-twitter.png";
import footerLinkedin from "./footer-linkedin.png";

import termsOfUse from "./terms-of-use.pdf";
import privacyPolicy from "./privacy-policy.pdf";

const currentDate = new Date();

class Footer extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <HeroGridCard className={`${globalStyles["hero--footer"]}`} color="white" justifyContent="center" alignItems="center" style={{maxWidth: "unset"}}>
        <Box className={`${globalStyles["hero--footer-stripes"]}`}>
          <Box className={`${globalStyles["hero--footer-stripes-inner"]}`}>
            {/* <Box className={`${globalStyles["hero--footer-stripe-1"]}`}/> */}
            <Box className={`${globalStyles["hero--footer-stripe-2"]}`}/>
            <Box className={`${globalStyles["hero--footer-stripe-3"]}`}/>
          </Box>
        </Box>
        <Box style={{position: "relative", zIndex: "1", width: "100%", maxWidth: "1164px", margin: "0 auto", padding: "50px 32px 0"}}>
          <Box className={`${globalStyles["hero--footer-wrapper"]}`}>
            <Box className={`${globalStyles["hero--footer-left"]}`}>
              <Link to="/" style={{display: "block"}}>
                <img src={LogoDark} className="navbar__logo" />
              </Link>
              <a to="mailto:info@skodel.com" style={{display: "block", marginTop: "40px", fontSize: "17px", lineHeight: "27px", textDecoration: "none", color: "#fff"}}>info@skodel.com</a>
              <Box style={{display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "24px"}}>
                <a href={termsOfUse} target="_blank" download style={{display: "block", fontSize: "17px", lineHeight: "27px", textDecoration: "none", color: "#fff"}}>Terms of Use</a>
                <Text style={{margin: "0 5px"}}>|</Text>
                <a href={privacyPolicy} target="_blank" download style={{display: "block", fontSize: "17px", lineHeight: "27px", textDecoration: "none", color: "#fff"}}>Privacy Policy</a>
              </Box>
              <Text style={{margin: "5px 0 0", fontSize: "17px", lineHeight: "27px", color: "#fff"}}>&copy; Skodel { currentDate.getFullYear() }</Text>
            </Box>
            <Box className={`${globalStyles["hero--footer-right"]}`}>
              <Box className={`${globalStyles["hero--footer-menu"]}`}>
                <Heading style={{margin: "0 0 20px", fontSize: "17px", lineHeight: "27px"}}>Company</Heading>
                <Link to="/" style={{display: "block", marginTop: "5px", fontSize: "13px", lineHeight: "26px", textDecoration: "none", color: "#fff"}}>Home</Link>
                <Link to="/vision" style={{display: "block", marginTop: "5px", fontSize: "13px", lineHeight: "26px", textDecoration: "none", color: "#fff"}}>Vision</Link>
                <Link to="/about" style={{display: "block", marginTop: "5px", fontSize: "13px", lineHeight: "26px", textDecoration: "none", color: "#fff"}}>About Us</Link>
              </Box>
              <Box className={`${globalStyles["hero--footer-menu"]}`}>
                <Heading style={{margin: "0 0 20px", fontSize: "17px", lineHeight: "27px"}}>Product</Heading>
                <Link to="/research" style={{display: "block", marginTop: "5px", fontSize: "13px", lineHeight: "26px", textDecoration: "none", color: "#fff"}}>Research</Link>
                <Link to="/technology" style={{display: "block", marginTop: "5px", fontSize: "13px", lineHeight: "26px", textDecoration: "none", color: "#fff"}}>Technology</Link>
              </Box>
              <Box className={`${globalStyles["hero--footer-menu"]}`}>
                <Heading style={{margin: "0 0 20px", fontSize: "17px", lineHeight: "27px"}}>Resources</Heading>
                <Link to="/contact-us" style={{display: "block", marginTop: "5px", fontSize: "13px", lineHeight: "26px", textDecoration: "none", color: "#fff"}}>Contact Us</Link>
              </Box>
              <Box className={`${globalStyles["hero--footer-menu"]}`}>
                <Heading style={{margin: "0 0 20px", fontSize: "17px", lineHeight: "27px"}}>Legal</Heading>
                <a href={termsOfUse} target="_blank" download style={{display: "block", marginTop: "5px", fontSize: "13px", lineHeight: "26px", textDecoration: "none", color: "#fff"}}>Terms of Use</a>
                <a href={privacyPolicy} target="_blank" download style={{display: "block", marginTop: "5px", fontSize: "13px", lineHeight: "26px", textDecoration: "none", color: "#fff"}}>Privacy Policy</a>
              </Box>
              <Box className={`${globalStyles["hero--footer-menu"]}`}>
                <Heading style={{margin: "0 0 20px", fontSize: "17px", lineHeight: "27px"}}>Follow</Heading>
                <Box style={{display: "flex", justifyContent: "flex-start", alignItems: "center"}}>
                  <a href="https://www.facebook.com/skodeltechnology/" target="_blank" style={{
                    marginRight: "8px"
                  }}>
                    <img src={footerFacebook} style={{display: "block", maxWidth: "16px"}}/>
                  </a>
                  <a href="https://twitter.com/Skodelwellbeing" target="_blank" style={{
                    marginRight: "8px"
                  }}>
                    <img src={footerTwitter} style={{display: "block", maxWidth: "16px"}}/>
                  </a>
                  <a href="https://www.linkedin.com/company/skodel" target="_blank">
                    <img src={footerLinkedin} style={{display: "block", maxWidth: "16px"}}/>
                  </a>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </HeroGridCard>
    );
  }
}

export default Footer;
