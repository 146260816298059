import React from "react";
import { Box, Flex } from "rebass";
import styled from "styled-components";

// grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
// padding: 0 32px;
const HeroLayoutGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 32px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    > .image {
      display: none;
    }
    > .copy {
      justify-content: flex-start !important;
    }
  }
`;

export const HeroLayout = ({
  copy,
  image,
  copyProps,
  imageProps,
  imageOnLeft=false,
  ...otherProps
}) => !imageOnLeft ? (
  <HeroLayoutGrid {...otherProps}>
    <Box
      alignItems="center"
      justifyContent="flex-end"
      className="copy"
      {...copyProps}
    >
      {copy}
    </Box>
    <Flex
      alignItems="center"
      justifyContent="center"
      className="image"
      pl={imageOnLeft ? 0 : 4}
      pr={imageOnLeft ? 4 : 0}
      {...imageProps}
    >
      {image}
    </Flex>
  </HeroLayoutGrid>
) : (
  <HeroLayoutGrid {...otherProps}>
        <Flex
      alignItems="center"
      justifyContent="center"
      className="image"
      px={4}
      {...imageProps}
    >
      {image}
    </Flex>
    <Box
      alignItems="center"
      justifyContent="flex-end"
      className="copy"
      {...copyProps}
    >
      {copy}
    </Box>
  </HeroLayoutGrid>
);

export const HeroLayoutEmpty = ({ ...otherProps }) => (
  <Box px="32px" {...otherProps} />
);
