import React from 'react';

import './Modal.css';

const modal = (props) => {
  return (
    <div className="modal-container" style={{visibility: props.show ? 'visible' : 'hidden'}}>
      <div className="modal-wrapper" style={{opacity: props.show ? '1' : '0'}}>
        <span className="close-modal-btn" onClick={props.close}>×</span>
        <div className="modal-body">
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default modal;