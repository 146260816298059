import { Heading, Box, Text, Flex, Button } from "rebass";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const PageSection = styled(Flex)({
  flexDirection: "column"
});

export const WidthMaxer = styled(Flex)({
  maxWidth: "1164px",
  width: "100%",
  flexDirection: "column",
  margin: "0 auto",
  flex: "1",
  zIndex: "1"
});

export const BrandButton = styled(NavLink)({
  background: "#ffffff",
  borderRadius: "4px",
  color: "#1565E5",
  fontWeight: "800",
  padding: "12px 24px",
  letterSpacing: "0px",
  textDecoration: "none",
  display: "inline-block"
});

export const BrandButtonA = styled.a({
  background: "#ffffff",
  borderRadius: "4px",
  color: "#1565E5",
  fontWeight: "800",
  padding: "12px 24px",
  letterSpacing: "0px",
  textDecoration: "none",
  display: "inline-block"
});

export const BrandButtonInput = styled.input({
  background: "#ffffff",
  borderRadius: "4px",
  color: "#1565E5",
  fontWeight: "800",
  padding: "12px 24px",
  letterSpacing: "0px",
  textDecoration: "none",
  display: "inline-block"
});

export const BrandButtonInverse = styled(Button)({
  background: "#FFFFFF",
  color: "rgb(93, 170, 242)",
  textTransform: "uppercase",
  fontWeight: "300",
  letterSpacing: "2px"
});
