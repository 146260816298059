import React, { useState } from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import Home from "./Home";
import AboutUs from "./AboutUs";
import Research from "./Research";
import Technology from "./Technology";
import Vision from "./Vision";
import { WidthMaxer } from "./shared";
import ContactUs from "./ContactUs";
import * as serviceWorker from "./serviceWorker";
import { Route, hashHistory, Switch } from "react-router";
import { BrowserRouter, NavLink, Link } from "react-router-dom";
import { Flex, Box } from "rebass";
import { Helmet } from "react-helmet";
import { slide as Menu } from 'react-burger-menu';
import { Menu as MenuIcon } from "styled-icons/boxicons-regular/Menu";
import LogoLight from "./logo1.svg"; // logo.png
import LogoDark from "./logo.png"; // logo.png
import Navbar from "./Navbar";

import { createBrowserHistory } from 'history';

// const history = createBrowserHistory();

const AppWrapper = ({ children, component, ...otherProps }) => {
  return (<BrowserRouter >
    <Helmet>
      <title>Skodel</title>
    </Helmet>
    <Route match="/" component={component} />
    </BrowserRouter>
    );
}
const App = ({ history }) => {
    const [hamburgerMenuOpen, setHamburgerMenuOpen] = useState(false);
  return (<>

      <Navbar history={history} setHamburgerMenuOpen={setHamburgerMenuOpen} hamburgerMenuOpen={hamburgerMenuOpen}/>
      <Box style={{position: "relative", zIndex: 0}}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about" component={AboutUs} />
          <Route path="/research" component={Research} />
          <Route path="/vision" component={Vision} />
          <Route path="/technology" component={Technology} />
          <Route path="/contact-us" component={ContactUs} />
        </Switch>
      </Box>
</>); }

ReactDOM.render(<AppWrapper component={App} />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
