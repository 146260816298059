import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Heading, Box, Text, Flex, Button, Image, Grid } from "rebass";

import styles from "./Vision.module.css";
import homeStyles from './Home.module.css';
import globalStyles from "./Global.module.css";

import { PageSection, WidthMaxer, BrandButton } from "./shared";
import { HeroGrid, HeroGridCard } from "./HeroGrid";
import { HeroLayoutEmpty } from "./HeroLayout";
import Footer from './Footer';

import logo from "./logo.svg";
import VisionIconStarting from "./vision-icon-starting.png";
import VisionIconMeasure from "./vision-icon-measure.png";
import VisionIconMonitor from "./vision-icon-monitor.png";
import VisionEducatingFuture from "./vision-educating-future.png";

const OurChildrenGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 50px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const PrepareChildrenGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 32px;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;


const EducatingForFutureGrid = styled(Box)`
  display: grid;
  grid-template-columns: 2fr 1fr;
  .image {
    margin-left: 36px;
  }
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    .image {
      display: none;
    }
  }
`;

const EducatingForFuture = ({ style, ...otherProps }) => (
  <EducatingForFutureGrid {...otherProps}>
    <Box>
      <Heading style={{fontSize: "22px", color: "#222A41"}}>Educating for the future</Heading>
      <Text style={{maxWidth: "532px", marginTop: "8px", fontSize: "17px", lineHeight: "27px", color: "#737B8B"}}>The education model has not made any significant change for over 50 years. We continue to define success in education by our performance in strict academic functions, or as some call it, hard skills. The evidence over the last 10 years shows, without doubt, that this model is no longer fit for purpose. Mental health issues are alarmingly on the rise, whilst academic performance remains fairly stagnant.</Text>
    </Box>{" "}
    <Flex style={{alignItems: "center", justifyContent: "flex-end"}}>
      <Image src={VisionEducatingFuture} className="image" style={{maxHeight: "228px", width: "auto"}}/>
    </Flex>
  </EducatingForFutureGrid>
);

class Vision extends Component {
  render() {
    return (
      <HeroGrid style={{position: "relative", overflow: "hidden", rowGap: "0"}}>
        <Helmet>
          <title>Skodel - Vision</title>
        </Helmet>
        <HeroGridCard className={`${styles["hero--vision"]}`} color="white" style={{maxWidth: "unset"}}>
          <Box className={`${styles["hero--vision-stripes"]} brand-stripes`}>
            <Box className={`${styles["hero--vision-stripes-inner"]}`}>
              <Box className={`${styles["hero--vision-stripe-1"]}`}/>
              <Box className={`${styles["hero--vision-stripe-2"]}`}/>
              <Box className={`${styles["hero--vision-stripe-3"]}`}/>
              <Box className={`${styles["hero--vision-stripe-4"]}`}/>
              <Box className={`${styles["hero--vision-stripe-5"]}`}/>
              <Box className={`${styles["hero--vision-stripe-6"]}`}/>
              <Box className={`${styles["hero--vision-stripe-7"]}`}/>
              <Box className={`${styles["hero--vision-stripe-8"]}`}/>
              <Box className={`${styles["hero--vision-stripe-9"]}`}/>
              <Box className={`${styles["hero--vision-stripe-10"]}`}/>
              <Box className={`${styles["hero--vision-stripe-11"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty className="hero" style={{flexDirection: "column", zIndex: "9001", paddingTop: "200px"}}>
            <WidthMaxer style={{maxWidth: "1100px"}}>
              <Flex justifyContent="center" style={{marginBottom: "110px"}}>
                <Heading style={{fontSize: "62px", letterSpacing: "2px", color: "#ffffff"}}>Vision</Heading>
              </Flex>
              <Box className={`${styles["hero--vision-box-text"]}`} style={{borderRadius: "10px", boxShadow: "0 42px 84px 0 rgba(36, 43, 75, 0.15)"}}>
                <EducatingForFuture />
              </Box>
            </WidthMaxer>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${styles["impact--vision"]}`}>
          <HeroLayoutEmpty flexDirection="column" alignSelf="center" style={{maxWidth: "1240px"}}>
            <Box className={`${styles["impact--vision-wrapper"]}`}>
              <Heading className={`${styles["impact--vision-title"]}`} style={{maxWidth: "816px"}}>The impacts of large scale social shifts, such as gender neutralism, social media, greater secularity, the gig economy and more are yet to be fully realised.{" "}</Heading>
              <Box className={`${styles["impact--vision-box"]}`}>
                <OurChildrenGrid>
                  <Text className={`${styles["impact--vision-text"]}`}>Our children are entering a world that offers them more freedom than ever before and roles are no longer clearly defined. This is a good thing, with greater freedoms comes greater opportunity, however, it also poses incredible challenges that previous generations did not face.</Text>
                  <Text className={`${styles["impact--vision-text"]}`}>Given the recency of these shifts, it is difficult to accurately understand their full impact. What we do know is there is now more uncertainty about what the future looks like and we are seeing increasing levels of anxiety and depression.{" "}</Text>
                </OurChildrenGrid>
              </Box>
            </Box>
            <Box className={`${styles["impact--vision-wrapper"]}`}>
              <Heading className={`${styles["impact--vision-title"]}`} style={{maxWidth: "520px"}}>How do we prepare our children for a world when we are unsure what that world will look like?</Heading>
              <Box className={`${styles["impact--vision-box"]}`}>
                <Text className={`${styles["impact--vision-text"]}`}>Whilst there are still unknowns, it is undeniable that change is necessary if we are to educate for future generations. And we believe this change lies in wellbeing. There must be a way to connect wellbeing to academic performance so that children leave our education system as purpose driven individuals, with a clear understanding of who they are and the desire and ability to positively contribute to the betterment of society. We are helping schools do this by simplifying the process of getting to know the every child.</Text>
              </Box>
            </Box>
            <PrepareChildrenGrid className={`${styles["prepare--vision"]}`}>
              <Box className={`${styles["prepare--vision-wrapper"]}`}>
                <Image src={VisionIconMeasure} />
                <Heading className={`${styles["prepare--vision-title"]}`}>Understand</Heading>
                <Text className={`${styles["prepare--vision-desc"]}`}>Architecting a reliable approach to understanding complex wellbeing issues and simply communicating these to educational stakeholders.</Text>
              </Box>
              <Box className={`${styles["prepare--vision-wrapper"]}`}>
                <Image src={VisionIconMonitor} />
                <Heading className={`${styles["prepare--vision-title"]}`}>Improve</Heading>
                <Text className={`${styles["prepare--vision-desc"]}`}>Empowering school communities with the information they need to make the right decisions at the right time.</Text>
              </Box>
            </PrepareChildrenGrid>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${styles["start--vision"]}`}>
          <Box className={`${styles["start--vision-stripes"]} brand-stripes`}>
            <Box className={`${styles["start--vision-stripes-inner"]}`}>
              <Box className={`${styles["start--vision-stripe-1"]}`}/>
              <Box className={`${styles["start--vision-stripe-2"]}`}/>
              <Box className={`${styles["start--vision-stripe-3"]}`}/>
              <Box className={`${styles["start--vision-stripe-4"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty className={`${styles["start--vision-content"]}`}>
            <Image src={VisionIconStarting} />
            <Heading className={`${styles["start--vision-title"]}`}>The starting point to delivering better wellbeing outcomes is being able to deeply understand and measure it across the different domains at individual, group and school levels.</Heading>
            <Text className={`${styles["start--vision-text"]}`}>"Skodel has developed a unique system that does exactly this, empowering school communities to make more informed decisions and build a community of care that extends beyond academic performance."</Text>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${homeStyles["hero--cta"]}`} color="white" justifyContent="center" alignItems="center" style={{maxWidth: "unset"}}>
          <Box className={`${homeStyles["hero--cta-stripes"]}`}>
            <Box className={`${homeStyles["hero--cta-stripes-inner"]}`}>
              <Box className={`${homeStyles["hero--cta-stripe-1"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-2"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-3"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-4"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-5"]}`}/>
              {/* <Box className={`${homeStyles["hero--cta-stripe-6"]}`}/> */}
              <Box className={`${homeStyles["hero--cta-stripe-7"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty style={{zIndex: "9001", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "calc(100% - 60px)", maxWidth: "740px", margin: "0 30px -420px", padding: "40px", backgroundColor: "#fff", borderRadius: "15px", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
            <Heading style={{fontSize: "36px", lineHeight: "50px", textAlign: "center", color: "#232D42", maxWidth: "508px"}}>Do you share our vision?</Heading>
            <Flex style={{marginTop: "32px"}}>
              <BrandButton className={`${globalStyles["btn"]}`} to="/contact-us" style={{background: "#AA63FF", color: "#FFFFFF", boxShadow: "0 8px 26px 0 rgba(170, 99, 255, 0.30)"}}>Contact Us</BrandButton>
            </Flex>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <Footer></Footer>
      </HeroGrid>
    );
  }
}

export default Vision;
