import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { Heading, Box, Text, Flex, Button, Image, Grid } from 'rebass';

import styles from './Research.module.css';
import globalStyles from './Global.module.css';
import homeStyles from './Home.module.css';

import { PageSection, WidthMaxer, BrandButton, BrandButtonA } from './shared';
import { HeroLayout, HeroLayoutEmpty } from './HeroLayout';
import { HeroGrid, HeroGridCard } from './HeroGrid';
import Footer from './Footer';

import logo from './logo.svg';
import Research2Screenshot from './research-2-screenshot.webp';
import ResearchWorldClass from './research-world-class.webp';
import ResearchHero from './research-hero.png';
import Research1Screenshot from './research-1-screenshot.png';
import ResearchDataScienceModels from './research-data-science-models.png';
import ResearchEvolve from './research-evolve.png';
import ResearchDeeplyUnderstand from './research-deeply-understand.png';
import ResearchInteractiveFeedback from './research-interactive-feedback.png';
import ResearchMaximiseDataValidity from './research-maximise-data-validity.png';
import ResearchMonitorVsMeasure from './research-monitor-vs-measure.png';
import ResearchDeceptivelyIcon from './research-deceptively-icon.png';
import ResearchGoodDataIcon from './research-good-data-icon.png';

const Card = ({ title, caption, image, isOpen }) => (
	<Box className={`${styles['ecological--research__grid']}`}>
		<Box style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
			<Heading style={{margin: '0 0 24px', fontSize: '22px', lineHeight: '30px'}}>{title}</Heading>
			<Text style={{fontSize: '17px', lineHeight: '30px', color: '#7b7b7b'}}>{caption}</Text>
		</Box>
		<Flex alignItems="center" justifyContent="flex-end">
			<Image src={image} />
		</Flex>
	</Box>
);

class Research extends Component {
	constructor(props) {
		super(props);
		this.state = {
			activeTab : 0
		};
		this.handleSelect = this.handleSelect.bind(this);
	}
	
	handleSelect(index) {
		this.setState({
      activeTab: index
		});
	}

	render() {
		const { activeTab } = this.state;

		return (
			<HeroGrid style={{position: "relative", overflow: "hidden", rowGap: "0"}}>
				<Helmet>
					<title>Skodel - Research</title>
				</Helmet>
				<HeroGridCard className={`${styles['hero--research']}`} color="white" px="0" style={{maxWidth: 'unset'}}>
					<Box className={`${styles['hero--research-stripes']} brand-stripes`}>
						<Box className={`${styles['hero--research-stripes-inner']}`}>
							<Box className={`${styles['hero--research-stripe-1']}`}/>
							<Box className={`${styles['hero--research-stripe-2']}`}/>
							<Box className={`${styles['hero--research-stripe-3']}`}/>
							<Box className={`${styles['hero--research-stripe-4']}`}/>
							<Box className={`${styles['hero--research-stripe-5']}`}/>
							<Box className={`${styles['hero--research-stripe-6']}`}/>
							<Box className={`${styles['hero--research-stripe-7']}`}/>
							<Box className={`${styles['hero--research-stripe-8']}`}/>
							<Box className={`${styles['hero--research-stripe-9']}`}/>
						</Box>
					</Box>
					<HeroLayout className={`${styles['hero__content--research']}`} style={{alignItems: 'flex-start', zIndex: '9001'}} copyProps={{style: {justifyContent: 'flex-start'}}} imageProps={{style: {justifyContent: 'flex-end'}}}
						copy={
							<Box className={`${styles['hero__text--research']}`}>
								<Heading className={`${globalStyles['hero__title']}`}>The power is in the research</Heading>
								<Heading className={`${globalStyles['hero__subtitle']}`}>Skodel works with leading researchers to develop scientifically reliable tools to monitor wellbeing.</Heading>
								<Flex className={`${styles['hero__button--research']}`}>
									<BrandButtonA className={`${globalStyles["btn"]}`} href={process.env.PUBLIC_URL + '/research.pdf'} style={{color: '#329e94'}}>Download Research</BrandButtonA>
								</Flex>
							</Box>
						}
						image={
							<Box className={`${styles['hero__image--research']}`}>
								<Image src={ResearchHero} />
							</Box>
						}
					/>
				</HeroGridCard>
				<HeroGridCard className={`${styles['team--research']}`}>
					<Box className={`${styles['team--research-stripes']} brand-stripes`}>
						<Box className={`${styles['team--research-stripes-inner']}`}>
							<Box className={`${styles['team--research-stripe-1']}`}/>
							<Box className={`${styles['team--research-stripe-2']}`}/>
						</Box>
					</Box>
					<WidthMaxer>
						<HeroLayout className={`${styles['team__wrapper--research']}`} imageOnLeft imageProps={{alignItems: "flex-start", style: {paddingLeft: 0}}}
							copy={
								<Box className={`${styles['team__text--research']}`}>
									<Heading>A truly world class research team</Heading>
									<Text className={`${styles['team__desc--research']}`}>Building accurate monitoring frameworks for schools doesn't happen by mistake. It takes a team of research specialists, a group of committed schools and highly skilled product developers tirelessly working together to unlock the true potential of effective wellbeing management systems.</Text>
									<Text className={`${styles['team__desc--research']}`}>Our team of researchers is spearheaded by leading academics from the University of Oxford and supported by The Scots College to develop and validate the most effective proactive model for positive wellbeing management in schools.</Text>
									<Flex className={`${styles['team__button--research']}`}>
										<BrandButton className={`${globalStyles["btn"]}`} to="/about" style={{background: '#AA63FF', color: '#FFFFFF', boxShadow: '0 8px 26px 0 rgba(170, 99, 255, 0.30)'}}>Meet the Team</BrandButton>
									</Flex>
								</Box>
							}
							image={
								<Box className={`${styles['team__image--research']}`}>
									<Image src={ResearchWorldClass} />
								</Box>
							}
						/>
					</WidthMaxer>
				</HeroGridCard>
				<HeroGridCard className={`${styles['lead--research']}`}>
					<HeroLayoutEmpty>
						<Heading className={`${styles['lead__title--research']}`}>Thorough research leads to better outcomes</Heading>
						<Box style={{gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))', display: 'grid', gridGap: '54px'}}>
							<Box className={`${styles['lead__text--research']}`}>
								<Image src={ResearchDeceptivelyIcon}/>
								<Heading >Deceptively Powerful</Heading>
								<Text className={`${styles['lead__desc--research']}`}>We believe that developing children into the best version of themselves starts with uncomplciating the process of getting to know them. We obsessively seek out the best way to do this for schools. Stepping away from large scale surveys to eliminate complexity, our research uncovered the most salient questions needed to monitor child wellbeing resulting in the development of a self-reporting tool that is effortlessly simple to run yet endlessly powerful in action.</Text>
							</Box>
							<Box className={`${styles['lead__text--research']}`}>
								<Image src={ResearchGoodDataIcon}/>
								<Heading >What good is bad data?</Heading>
								<Text className={`${styles['lead__desc--research']}`}>The ability to make the right decision is heavily dependent upon the reliability and timing of your data. Skodel collects and analyses data in real time and retrospectively, adopting research supported methods that reduce data biases, enhance speed to action and maximise data validity. Schools that adopt Skodel can have confidence in the data they use to make decisions that shape the development of student wellbeing.</Text>
							</Box>
						</Box>
					</HeroLayoutEmpty>
				</HeroGridCard>
				<HeroGridCard style={{ zIndex: '9001', display: 'flex' }}>
					<HeroLayoutEmpty style={{alignItems: 'center', justifyContent: 'stretch', display: 'flex', flex: '1'}}>
						<Flex className={`${styles['count--technology']}`} py={5} px={3} bg="#FFFFFF" style={{boxShadow: '0 22px 60px 0 rgba(24, 50, 92, 0.10)', flex: '1', borderRadius: '10px'}}>
							<Flex flexDirection="column" alignItems="center">
								<Text fontSize={6} fontWeight={800} color="rgb(100, 130, 244)">50+</Text>
								<Text>years of research</Text>
							</Flex>
							<Flex flexDirection="column" alignItems="center">
								<Text fontSize={6} fontWeight={800} color="rgb(100, 130, 244)">300+</Text>
								<Text>literature reviewed</Text>
							</Flex>
							<Flex flexDirection="column" alignItems="center">
								<Text fontSize={6} fontWeight={800} color="rgb(100, 130, 244)">90+</Text>
								<Text>wellbeing domains</Text>
							</Flex>
						</Flex>
					</HeroLayoutEmpty>
				</HeroGridCard>
				<HeroGridCard className={`${styles['hero--technology']}`} color="white" style={{maxWidth: 'unset', padding: '0', flexDirection: 'column', justifyContent: 'center'}}>
					<Box className={`${styles['hero--technology-stripes']} brand-stripes`}>
						<Box className={`${styles['hero--technology-stripes-inner']}`}>
							<Box className={`${styles['hero--technology-stripe-1']}`}/>
							<Box className={`${styles['hero--technology-stripe-2']}`}/>
							<Box className={`${styles['hero--technology-stripe-3']}`}/>
							<Box className={`${styles['hero--technology-stripe-4']}`}/>
							<Box className={`${styles['hero--technology-stripe-5']}`}/>
							<Box className={`${styles['hero--technology-stripe-6']}`}/>
						</Box>
					</Box>
					<WidthMaxer>
						<HeroLayout className={`${styles['data__wrapper--research']}`} style={{zIndex: '9001'}} imageOnLeft copyProps={{style: {display: 'flex', justifyContent: 'flex-start', flexDirection: 'column'}, alignItems: 'flex-start'}} imageProps={{style: {justifyContent: 'flex-start'}, alignItems: 'flex-start', paddingLeft: '0'}}
							copy={
								<Box className={`${styles['data__text--research']}`}>
									<Heading>Data science models that work</Heading>
									<Text className={`${styles['data__desc--research']}`}>Skodel empowers education communities with the ability to get to know every child on a deeper level without disrupting your current processes.</Text>
									<Text className={`${styles['data__desc--research']}`}>Our data models alert you of important changes in child wellbeing and uncover the key driving factors of positive and negative emotional states at the individual and collective level.</Text>
									<Flex className={`${styles['data__button--research']}`}>
										<BrandButton className={`${globalStyles["btn"]}`} to="/about" style={{backgroundColor: '#ffffff', color: '#d57842'}}>More on Our Research</BrandButton>
									</Flex>
								</Box>
							}
							image={
								<Box className={`${styles['data__image--research']}`}>
									<Image src={ResearchDataScienceModels}/>
								</Box>
							}
						/>
					</WidthMaxer>
				</HeroGridCard>
				<HeroGridCard className={`${styles['ecological--research']}`}>
					<HeroLayoutEmpty>
						<Heading style={{fontSize: '36px', fontWeight: '700', lineHeight: '50px'}} textAlign="center">Ecological momentary assessment</Heading>
						<Heading style={{maxWidth: '644px', margin: '16px auto 0', fontSize: '17px', fontWeight: 'normal', lineHeight: '30px', color: '#7b7b7b'}} textAlign="center">You can’t establish an effective system for early intervention without a free flowing stream of accurate real time data. EMA is built to achieve that.</Heading>
						<Box className={`${styles['ecological--research__grid-desktop']}`}>
							<Tabs className={`${globalStyles['custom--tabs']}  ${styles['tab--research']}`}>
								<TabList className={`${globalStyles['tab__header']}`}>
									<Tab className={`${globalStyles['tab__nav--list']} ${activeTab == 0 ? `${globalStyles['tab--active']}` : ''}`} onClick={() => this.handleSelect(0)}>Maximise data validity</Tab>
									<Tab className={`${globalStyles['tab__nav--list']} ${activeTab == 1 ? `${globalStyles['tab--active']}` : ''}`} onClick={() => this.handleSelect(1)}>Deeply understand children</Tab>
									<Tab className={`${globalStyles['tab__nav--list']} ${activeTab == 2 ? `${globalStyles['tab--active']}` : ''}`} onClick={() => this.handleSelect(2)}>Monitor vs measure</Tab>
									<Tab className={`${globalStyles['tab__nav--list']} ${activeTab == 3 ? `${globalStyles['tab--active']}` : ''}`} onClick={() => this.handleSelect(3)}>Interactive feedback in real time</Tab>
								</TabList>
								<TabPanel>
									<Card image={ResearchMaximiseDataValidity} title={'Maximise data validity'} caption={'Reduce recall bias that exists in questionnaire studies by collecting information on children ongoingly in a natural setting.'}/>
								</TabPanel>
								<TabPanel>
									<Card image={ResearchDeeplyUnderstand} title={'Deeply Understand Children'} caption={'Understand children under different contexts, exploring their emotional responses to bad grades, sporting disappointments or highly stressful situations.'}/>
								</TabPanel>
								<TabPanel>
									<Card image={ResearchMonitorVsMeasure} title={'Monitor vs measure'} caption={'Don’t just measure wellbeing retrospectively once a year, monitor it on an ongoing basis so you can identify and act upon issues as they arise.'}/>
								</TabPanel>
								<TabPanel>
									<Card image={ResearchInteractiveFeedback} title="Interactive feedback in real time" caption={'Hear from children during the moments that matter to uncover insights that will change the student experience at your school.'}/>
								</TabPanel>
							</Tabs>
						</Box>
						<Box className={`${styles['ecological--research__grid-mobile']}`}>
							<Box style={{backgroundColor: "#ffffff", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
								<Card image={ResearchMaximiseDataValidity} title={'Maximise data validity'} caption={'Reduce recall bias that exists in questionnaire studies by collecting information on children ongoingly in a natural setting.'}/>
							</Box>
							<Box style={{marginTop: "30px", backgroundColor: "#ffffff", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
								<Card image={ResearchDeeplyUnderstand} title={'Deeply Understand Children'} caption={'Understand children under different contexts, exploring their emotional responses to bad grades, sporting disappointments or highly stressful situations.'}/>
							</Box>
							<Box style={{marginTop: "30px", backgroundColor: "#ffffff", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
								<Card image={ResearchMonitorVsMeasure} title={'Monitor vs measure'} caption={'Don’t just measure wellbeing retrospectively once a year, monitor it on an ongoing basis so you can identify and act upon issues as they arise.'}/>
							</Box>
							<Box style={{marginTop: "30px", backgroundColor: "#ffffff", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
								<Card image={ResearchInteractiveFeedback} title="Interactive feedback in real time" caption={'Hear from children during the moments that matter to uncover insights that will change the student experience at your school.'}/>
							</Box>
						</Box>
					</HeroLayoutEmpty>
				</HeroGridCard>
				<HeroGridCard className={`${styles['evolve--research']}`} style={{ minHeight: 'unset' }} justifyContent="center">
					<HeroLayoutEmpty style={{display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
						<Flex style={{borderRadius: '20px', display: 'inline-block', }} color="#39cc99" justifyContent="center" mb={4} mt={2} mx="auto" p={2} px={3} bg="rgba(87, 210, 194, 0.1)">
							<Heading fontWeight={800} fontSize={2}>Evolving Research</Heading>
						</Flex>
						<Heading textAlign="center" style={{maxWidth: '968px', margin: '0 auto 24px', fontSize: '36px', lineHeight: '50px'}}>Through our advisory board, we have strong connections to the world's leading research organisations</Heading>
						<Text color="#737B8B" textAlign="center" style={{fontSize: '17px', lineHeight: '30px'}}>Which ensures we stay up to date and implement the latest findings in child wellbeing.</Text>
						<Image className={`${styles['evolve__image--research']}`} src={ResearchEvolve}/>
					</HeroLayoutEmpty>
				</HeroGridCard>
				<HeroGridCard className={`${homeStyles["hero--cta"]}`} color="white" justifyContent="center" alignItems="center" style={{maxWidth: "unset"}}>
          <Box className={`${homeStyles["hero--cta-stripes"]}`}>
            <Box className={`${homeStyles["hero--cta-stripes-inner"]}`}>
              <Box className={`${homeStyles["hero--cta-stripe-1"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-2"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-3"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-4"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-5"]}`}/>
              {/* <Box className={`${homeStyles["hero--cta-stripe-6"]}`}/> */}
							<Box className={`${homeStyles["hero--cta-stripe-7"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty style={{zIndex: "9001", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "calc(100% - 60px)", maxWidth: "740px", margin: "0 30px -420px", padding: "40px", backgroundColor: "#fff", borderRadius: "15px", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
            <Heading style={{fontSize: "36px", lineHeight: "50px", textAlign: "center", color: "#232D42", maxWidth: "508px"}}>Ready to implement leading research into your school?</Heading>
            <Flex style={{marginTop: "32px"}}>
              <BrandButton className={`${globalStyles["btn"]}`} to="/contact-us" style={{background: "#AA63FF", color: "#FFFFFF", boxShadow: "0 8px 26px 0 rgba(170, 99, 255, 0.30)"}}>Get in Touch</BrandButton>
            </Flex>
          </HeroLayoutEmpty>
        </HeroGridCard>
				<Footer></Footer>
			</HeroGrid>
		);
	}
}

export default Research;
