import React, { useState, Component } from "react";
import ReactDOM from "react-dom";
import { Route, hashHistory, Switch } from "react-router";
import { BrowserRouter, NavLink, Link } from "react-router-dom";

import "normalize.css";
import "./index.css";
import globalStyles from "./Global.module.css";

import Home from "./Home";
import AboutUs from "./AboutUs";
import Research from "./Research";
import Technology from "./Technology";
import Vision from "./Vision";
import { WidthMaxer } from "./shared";
import ContactUs from "./ContactUs";
import * as serviceWorker from "./serviceWorker";
import { Flex, Box } from "rebass";
import { Helmet } from "react-helmet";
import { slide as Menu } from 'react-burger-menu';
import { Menu as MenuIcon } from "styled-icons/boxicons-regular/Menu";

import LogoLight from "./logo1.svg"; // logo.png
import LogoDark from "./logo.png"; // logo.png

class Navbar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollPosition: 0
    }
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    this.setState({
      scrollPosition: winScroll
    });
  }

  render() {
    const { history, hamburgerMenuOpen, setHamburgerMenuOpen } = this.props;
    const { scrollPosition } = this.state;
    const useLightColours = history.location.pathname === "/about";
    return (
      <Flex className={`navbar-outer ${useLightColours || scrollPosition > 0 ? "navbar-outer--active" : ""}`} style={{zIndex: "2", width: "100%", position: "absolute"}}>
        <Box className="hamburger-icon-wrap" >
          <Menu  onStateChange={(state) => setHamburgerMenuOpen(state.isOpen)} menuClassName={hamburgerMenuOpen ? "burger-menu-open" : ""} isOpen={hamburgerMenuOpen} customBurgerIcon={false}>
            <NavLink exact to="/" onClick={() => setHamburgerMenuOpen(false)} >
              <img src={LogoDark} style={{width: "95px"}} />
            </NavLink>
            <NavLink exact to="/" onClick={() => setHamburgerMenuOpen(false)}>Home</NavLink>
            <NavLink to="/research" onClick={() => setHamburgerMenuOpen(false)}>Research</NavLink>
            <NavLink to="/technology" onClick={() => setHamburgerMenuOpen(false)}>Technology</NavLink>
            <NavLink to="/vision" onClick={() => setHamburgerMenuOpen(false)}>Vision</NavLink>
            <NavLink to="/about" onClick={() => setHamburgerMenuOpen(false)} >About Us</NavLink>
            <a href="https://help.skodel.com">Help Center</a>
            <NavLink to="/contact-us" onClick={() => setHamburgerMenuOpen(false)}>Contact</NavLink>
            <div className="navbar__user">
              {/* <NavLink to="/signin">Sign In</NavLink> */}
              <a className={`${globalStyles["btn"]} ${"btn__user--join"}`} href="https://app.skodel.com">Teacher Login</a>
            </div>
          </Menu>
        </Box>
        <Box className={`${ scrollPosition > 0 ? "main--navbar scrolled" : "main--navbar" }`}>
          <Box className="navbar" px={4} style={{maxWidth: "1164px", zIndex: "2", justifyContent: "space-between", margin: "0 auto"}}>
            <Link to="/" style={{lineHeight: "1"}}>
              <img src={useLightColours || scrollPosition > 0 ? LogoLight : LogoDark} className="navbar__logo" />
            </Link>
            <div className="navbar__wrapper" style={{flex: "1"}}>
              <Flex justifyContent="space-between" alignItems="center">
                <div className="navbar__items">
                  <NavLink exact to="/">Home</NavLink>
                  <NavLink to="/research">Research</NavLink>
                  <NavLink to="/technology">Technology</NavLink>
                  <NavLink to="/vision">Vision</NavLink>
                  <NavLink to="/about">About Us</NavLink>
                  <a href="https://help.skodel.com">Help Center</a>
                  <NavLink to="/contact-us">Contact</NavLink>
                </div>
                <div className="navbar__user">
                  {/* <NavLink to="/signin">Sign In</NavLink> */}
                  <a className={`${globalStyles["btn"]} ${"btn__user--join"}`} href="https://app.skodel.com">Teacher Login</a>
                </div>
              </Flex>
            </div>
            <Flex className="hamburger-icon-wrap" justifyContent="flex-end" alignItems="flex-end" >
              <a style={{color: useLightColours || scrollPosition > 0 ?  "#5da9f2" : "#ffffff"}} href="#" onClick={(e) => {
                  e.preventDefault();
                  setHamburgerMenuOpen(true);
                  return false;}}>
                <MenuIcon width="32" height="32" className="hamburger-icon" />
              </a>
            </Flex>
          </Box>
        </Box>
      </Flex>
    );
  }
}

export default Navbar;
