import React from "react";
import { Box } from "rebass";
import { PageSection, WidthMaxer, BrandButton } from "./shared";
import styled from "styled-components";

export const HeroGrid = styled(Box)`
  display: grid;
  row-gap: 32px;
`;

export const HeroGridCard = ({ style, ...otherProps }) => (
  <WidthMaxer
    {...otherProps}
    style={{
      // minHeight: "80vh",
      ...style
    }}
  />
);
