import React, { Component } from "react";
import { Heading, Box, Text, Flex, Button, Image, Link } from "rebass";
import styled from "styled-components";
import NetlifyForm from "react-netlify-form";
import { Helmet } from "react-helmet";

import styles from "./Technology.module.css";
import contactStyles from "./ContactUs.module.css";
import globalStyles from "./Global.module.css";

import { PageSection, WidthMaxer, BrandButton, BrandButtonInput } from "./shared";
import { HeroGrid, HeroGridCard } from "./HeroGrid";
import { HeroLayoutEmpty } from "./HeroLayout";
import Footer from './Footer';

import logo from "./logo.svg";
import CollectIcon from "./technology-icon-collect.png";
import FacilitateIcon from "./technology-icon-facilitate.png";
import OrganiseIcon from "./technology-icon-organise.png";
import { FancyListItem } from "./Home";
import ListIcon from "./list-icon.png";
import facebookIcon from "./facebook-square.svg";
import linkedinIcon from "./linkedin.svg";
import twitterIcon from "./twitter-square.svg";
import personIcon from "./ios-person.svg";
import atIcon from "./ios-at.svg";
import mailIcon from "./ios-mail.svg";

const securityPoints = [
  "All data is encrypted at all times",
  "Support for two-factor authentication",
  "SSO authentication protocols",
  "Role based access controls",
  "Alerts for suspicious behaviour"
];
class Technology extends Component {
  render() {
    return (
      <HeroGrid style={{position: "relative", overflow: "hidden", rowGap: "0"}}>
        <Helmet>
          <title>Skodel - Contact Us</title>
        </Helmet>
        <HeroGridCard className={`${styles["hero--technology"]}`} color="white" style={{zIndex: "3", maxWidth: "unset", marginBottom: "-568px", padding: "0"}}>
          <Box className={`${contactStyles["hero--contact-stripes"]} brand-stripes`}>
            <Box className={`${contactStyles["hero--contact-stripes-inner"]}`}>
              <Box className={`${contactStyles["hero--contact-stripe"]} ${ contactStyles["hero--contact-stripe-1"]}`}/>
              <Box className={`${contactStyles["hero--contact-stripe"]} ${ contactStyles["hero--contact-stripe-2"]}`}/>
              <Box className={`${contactStyles["hero--contact-stripe"]} ${ contactStyles["hero--contact-stripe-3"]}`}/>
              <Box className={`${contactStyles["hero--contact-stripe"]} ${ contactStyles["hero--contact-stripe-4"]}`}/>
              <Box className={`${contactStyles["hero--contact-stripe"]} ${ contactStyles["hero--contact-stripe-5"]}`}/>
              <Box className={`${contactStyles["hero--contact-stripe"]} ${ contactStyles["hero--contact-stripe-6"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty className={`${contactStyles["hero--contact"]}`} mt={6} mx="auto" style={{display: "flex", width: "100%", maxWidth: "1164px", zIndex: "9001", paddingRight: "0", paddingLeft: "0"}}>
            <Box className={`${contactStyles["hero--contact-form"]}`}>
              <Box className={`${contactStyles["contact--grid"]}`}>
                <Box p={3} className={`${contactStyles["contact--form-title"]}`}>
                  <Heading fontWeight={800} style={{maxWidth: "350px", fontSize: "42px", lineHeight: "52px"}} my={3}>Want to get in contact with us?</Heading>
                  <Text my={3} style={{fontSize: "17px", lineHeight: "30px", margin: "16px 0 0"}}>Follow the prompts and we will be in contact with you shortly.</Text>
                </Box>
                <Box p={3} className={`${contactStyles["contact--form-alternative"]}`}>
                  <div className={`${contactStyles["contact--form-separator"]}`}/>
                  <Text my={3} style={{fontSize: "17px", lineHeight: "30px", margin: "24px 0 0"}}>or get in touch with social media</Text>
                  <Flex className={`${contactStyles["contact--social-list"]}`}>
                    <Link className={`${globalStyles["btn"]}`} href="https://www.facebook.com/skodeltechnology/">
                      <Image src={facebookIcon} />
                    </Link>
                    <Link className={`${globalStyles["btn"]}`} href="https://twitter.com/Skodelwellbeing">
                      <Image src={twitterIcon} />
                    </Link>
                    <Link className={`${globalStyles["btn"]}`} href="https://www.linkedin.com/company/skodel">
                      <Image src={linkedinIcon} />
                    </Link>
                  </Flex>
                </Box>
                <Box p={3} className={`${contactStyles["contact--form-wrapper"]}`}>
                  <NetlifyForm name="Contact Form">
                    {({ loading, error, success }) => (
                      <div>
                        {loading && (
                          <Heading fontWeight={800} fontSize={5}>Loading...</Heading>
                        )}
                        {error && (
                          <Heading fontWeight={800} fontSize={5}>Your information was not sent. Please try again later.</Heading>
                        )}
                        {success && (
                          <Heading fontWeight={800} fontSize={5}>Thank you for contacting us!</Heading>
                        )}
                        {!loading &&
                          !success && (
                            <div>
                              <Box style={{position: "relative", display: "grid", gridTemplateColumns: "1fr", marginBottom: "25px"}} >
                                <input className={`${contactStyles["contact--input"]}`} type="text" placeholder="Name" name="name" required/>
                                <label className={`${contactStyles["contact--form-icon"]}`}><Image src={personIcon} /></label>
                              </Box>
                              <Box style={{position: "relative", display: "grid", gridTemplateColumns: "1fr", marginBottom: "25px"}} >
                                <input className={`${contactStyles["contact--input"]}`} type="email" placeholder="Email" name="email" required/>
                                <label className={`${contactStyles["contact--form-icon"]}`}><Image src={atIcon} /></label>
                              </Box>
                              <Box style={{position: "relative", display: "grid", gridTemplateColumns: "1fr", marginBottom: "25px"}} >
                                <textarea className={`${contactStyles["contact--textarea"]}`} placeholder="Message" name="message" required/>
                                <label className={`${contactStyles["contact--form-icon"]}`}><Image src={mailIcon} /></label>
                              </Box>
                              <BrandButton className={`${globalStyles["btn"]} ${contactStyles["contact--button"]}`} as="button" style={{border: "none"}}>Submit</BrandButton>
                            </div>
                          )}
                      </div>
                    )}
                  </NetlifyForm>
                </Box>
              </Box>
            </Box>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <Footer></Footer>
      </HeroGrid>
    );
  }
}

export default Technology;
