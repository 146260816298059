import React, { Component } from "react";
import { Heading, Box, Text, Flex, Button, Image } from "rebass";
import styled from "styled-components";
import ReactPlayer from 'react-player';
import { Link } from "react-router-dom";

import { PageSection, WidthMaxer, BrandButton, BrandButtonA } from "./shared";
import { HeroGrid, HeroGridCard } from "./HeroGrid";
import { HeroLayout, HeroLayoutEmpty } from "./HeroLayout";
import Modal from './Modal';
import Footer from './Footer';

import styles from "./Home.module.css";
import globalStyles from "./Global.module.css";

// import IpadScreenshot from "./homepage-hero.svg";
import IpadScreenshot from "./home-illustration.png";
import Home20YearsIcon from "./home-20-years-icon.png";
import HomeIntuitiveIcon from "./home-intuitive-icon.png";
import HomeSecurityIcon from "./home-security-icon.png";
import HomeTechnologyIcon from "./home-technology-icon.png";
import HomeResearchMeetsTechnologyScrenshot from "./home-research-meets-technology.png";
import HomeResearchMeetsTechnologyIcon from "./home-research-meets-technology-icon.png";
import HomeBuiltForTeachersIcon from "./home-built-for-teachers-icon.png";
import HomeBuiltForTeachers from "./home-built-for-teachers.png";
import HomeWellbeingSurvey from "./home-wellbeing-survey.png";
import HomeGetAWellbeingPulse from "./home-get-a-wellbeing-pulse.png";
import HomeEasilyTrackStudentPositivity from "./home-easily-track-student-positivity.png";
import HomeUnderstandKeyDrivers from "./home-understand-key-drivers.png";
import HomeDigDeeperWithStudentVoice from "./home-dig-deeper-with-student-voice.png";
import HomeRetroOccupy from "./home-retro-occupy.jpg";
import HomeKogiCosby from "./home-kogi-cosby.jpg";
import HomeFixieTote from "./home-fixie-tote.jpg";
import ListIcon from "./list-icon.png";
import ListIconGreen from "./list-icon-2.png";
import ArrowPlayIcon from "./md-arrow-dropright-circle.svg";

const WhySkodelGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 90px 32px;
  max-width: 1104px;
  margin: 92px auto 0;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const LatestResearchGrid = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  grid-gap: 40px;
  max-width: 1104px;
  margin: 50px auto 0;
  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

const WhySkodelBox = ({ icon, heading, text, link, linkColor }) => (
  <Flex flexDirection="column" bg="white" style={{padding: "90px 60px 72px", borderRadius: "8px", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
    <Box textAlign="center" style={{position: "relative"}}>
      <Image src={icon} height={"114px"} width={"114px"} mr={3} style={{width: "114px", height: "114px", margin: "0", borderRadius: "50%", top: "-147px", left: "calc(50% - 57px)", position: "absolute"}} />
    </Box>
    <Heading fontSize={3} textAlign="center" style={{display: "flex", alignItems: "center", justifyContent: "center", position: "relative"}}>
      { heading }
    </Heading>
    <Text textAlign="center" style={{marginTop: "8px", fontSize: "14px", lineHeight: "24px", color: "#7b7b7b"}}>
      { text }
    </Text>
    <Link to="/" style={{marginTop: "30px", fontSize: "16px", fontWeight: "bold", textDecoration: "none", textAlign: "center", color: linkColor}}>
      { link }
    </Link>
  </Flex>
);

const LatestResearchBox = ({ imageUrl, heading, text}) => (
  <Flex flexDirection="column" bg="white" style={{justifyContent: "flex-start", borderRadius: "8px", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
    <Image src={imageUrl} style={{borderRadius: "8px 8px 0 0"}}/>
    <Box style={{padding: "24px 32px 32px"}}>
      <Heading fontWeight={700} style={{fontSize: "20px", lineHeight: "26px"}}>
        { heading }
      </Heading>
      <Text style={{marginTop: "8px", fontSize: "14px", lineHeight: "24px", color: "#7b7b7b"}}>
        { text }
      </Text>
    </Box>
  </Flex>
);

export const FancyListItem = ({ children, icon, ...otherProps }) => (
  <li {...otherProps} style={{fontWeight: 800, display: "flex", alignItems: "flex-start", margin: "16px 0 0", padding: "0"}}>
    <Image src={icon == "purple" ? ListIcon : ListIconGreen} width="24px" height="24px" mr={3} mb={2} style={{margin: "4px 16px 4px 0", borderRadius: "50%", boxShadow: "0 4px 8px 0 rgba(143, 174, 227, .29)"}}/>
    {children}
  </li>
);

class Home extends Component {
  constructor() {
    super();
    this.state = {
      isShowing: false,
      scrollPosition: 0
    }
  }

  openModalHandler = () => {
    this.setState({
      isShowing: true
    });
  }

  closeModalHandler = () => {
    this.setState({
      isShowing: false
    });
  }

  render() {
    return (
      <HeroGrid style={{position: "relative", overflow: "hidden", rowGap: "0"}}>
        <HeroGridCard className={`${styles["hero--home"]}`} color="white" style={{maxWidth: "unset", padding: "0"}}>
          <Box className={`${styles["hero--home-stripes"]}`}>
            <Box className={`${styles["hero--home-stripes-inner"]}`}>
              <Box className={`${styles["hero--home-stripe-1"]}`} />
              <Box className={`${styles["hero--home-stripe-2"]}`} />
              <Box className={`${styles["hero--home-stripe-3"]}`} />
              <Box className={`${styles["hero--home-stripe-4"]}`} />
              <Box className={`${styles["hero--home-stripe-5"]}`} />
              <Box className={`${styles["hero--home-stripe-6"]}`} />
            </Box>
          </Box>
          <WidthMaxer>
            <HeroLayout mt={"200px"} style={{zIndex: "9001", margin: "140px auto 0"}} copyProps={{style: {position: "relative", zIndex: "1", display: "flex", alignItems: "flex-start", justifyContent: "flex-start"}}} imageProps={{style: {position: "relative", zIndex: "0", alignItems: "flex-start", justifyContent: "flex-start", maxWidth: "720px"}}}
              copy={
                <Box className={`${styles["hero__home--text"]}`} style={{marginTop: "92px"}}>
                  <Heading fontWeight={700} mb={3} style={{margin: "0", fontSize: "42px", lineHeight: "57px"}}>Give every student a voice that is heard</Heading>
                  <Text my={3} style={{margin: "20px 0 36px", fontSize: "17px", lineHeight: "30px"}}>Skodel is the simplest way to monitor, understand and improve student wellbeing.</Text>
                  <Flex py={5} className={`${styles["home__hero--button"]}`} flexWrap="wrap" style={{padding: "0"}}>
                    <BrandButton className={`${globalStyles["btn"]}`} to="/contact-us">Try it now</BrandButton>
                    <BrandButtonA className={`${globalStyles["btn"]}`} onClick={this.openModalHandler} style={{cursor: "pointer", display: "flex", alignItems: "center", background: "rgba(255, 255, 255, 0.1)", color: "#FFFFFF"}}>
                      <Image src={ArrowPlayIcon} style={{display: "block", width: "22px", marginBottom: "2px", marginRight: "5px"}} />
                      <span style={{display: "block"}}>Watch How it Works</span>
                    </BrandButtonA>
                  </Flex>
                </Box>
              }
              image={
                <Image className={`${styles["hero__home--image"]}`} src={IpadScreenshot}/>
              }
            />
          </WidthMaxer>
        </HeroGridCard>
        <Modal className="modal" show={this.state.isShowing} close={this.closeModalHandler}>
          <ReactPlayer url='https://www.youtube.com/watch?v=KxTMeojl3u0' playing={this.state.isShowing} width="calc(100% - 30px)" height="calc(100% - 30px)" style={{display: "block", position: "absolute", top: "15px", left: "15px"}} />
        </Modal>
        <HeroGridCard style={{position: "relative"}}>
          <HeroLayoutEmpty style={{display: "flex", flexDirection: "column"}}>
            <Flex color="rgb(91, 157, 233)" mx="auto" bg="rgb(237, 245, 252)" style={{borderRadius: "20px", marginTop: "0", marginBottom: "17px", padding: "8px 20px"}}>
              <Heading fontSize={2}>Pioneering the future of education</Heading>
            </Flex>
            <Heading fontWeight={600} textAlign="center" my={3} mx="auto" style={{width: "100%", maxWidth: "651px", marginTop: "0", marginBottom: "0", fontSize: "36px", lineHeight: "50px"}}>We help schools discover more about student wellbeing</Heading>
            <Text mx="auto" mt={5} textAlign="center" style={{maxWidth: "728px", marginTop: "24px", fontSize: "17px", lineHeight: "32px", color: "#737B8B"}}>Our easy-to-use wellbeing tool enables school communities to check-in regularly with their students. It is engaging for students and informative for teachers.</Text>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${styles["gawp__grid--section"]}`} style={{zIndex: "9001"}}>
          <HeroLayout className={`${styles["gawp__grid"]}`} imageOnLeft imageProps={{style: {position: "relative"}, alignItems: "flex-start", paddingTop: "42px"}}
            image={
              <div className={`${styles["home__get-a-wellbeing-pulse-image"]}`}>
                <div className={`${styles["home__pattern-big"]}`} />
                <div className={`${styles["home__pattern-medium"]}`} />
                <div className={`${styles["home__pattern-small"]}`} />
                <Image src={HomeGetAWellbeingPulse} />
              </div>
            }
            copy={
              <React.Fragment>
                <Heading fontWeight={600} style={{maxWidth: "100%", margin: "0", fontSize: "36px", lineHeight: "50px"}}>Get a wellbeing pulse</Heading>
                <Text my={3} style={{maxWidth: "600px", margin: "20px 0 0", fontSize: "17px", lineHeight: "32px", color: "#737B8B"}}>Deliver engaging 30 second check-ins to give every student an opportunity to communicate how they are going.</Text>
                <ul style={{display: "flex", flexDirection: "column", margin: "0", marginTop: "24px", padding: "0", listStyle: "none"}}>
                  <FancyListItem icon="purple">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Simple &amp; engaging</Text>
                  </FancyListItem>
                  <FancyListItem icon="purple">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Can be run regularly</Text>
                  </FancyListItem>
                  <FancyListItem icon="purple">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Give every student a voice</Text>
                  </FancyListItem>
                </ul>
              </React.Fragment>
            }
          />
        </HeroGridCard>
        <HeroGridCard className={`${styles["etsp__grid--section"]}`} style={{zIndex: "9001"}}>
          <HeroLayout className={`${styles["etsp__grid"]}`} copyProps={{paddingTop: "0"}} imageProps={{style: {position: "relative"}, alignItems: "flex-start", paddingLeft: "135px"}}
            copy={
              <React.Fragment>
                <Heading fontWeight={600} style={{margin: "0", fontSize: "36px", lineHeight: "50px"}}>Easily track<br/>student positivity</Heading>
                <Text my={3} style={{maxWidth: "600px", margin: "20px 0 0", fontSize: "17px", lineHeight: "32px", color: "#737B8B"}}>Quickly gauge positivity levels across different classes, custom groups or school wide.</Text>
                <ul style={{display: "flex", flexDirection: "column", margin: "0", marginTop: "24px", padding: "0", listStyle: "none"}}>
                  <FancyListItem icon="green">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Identify issues instantly</Text>
                  </FancyListItem>
                  <FancyListItem icon="green">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Pinpoint challenging times</Text>
                  </FancyListItem>
                  <FancyListItem icon="green">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Compare classes or custom groups</Text>
                  </FancyListItem>
                </ul>
              </React.Fragment>
            }
            image={
              <div className={`${styles["home__easily-track-student-positivity-image"]}`}>
                <div className={`${styles["home__pattern-big"]}`} />
                <div className={`${styles["home__pattern-medium"]}`} />
                <div className={`${styles["home__pattern-small"]}`} />
                <Image src={HomeEasilyTrackStudentPositivity} />
              </div>
            }
          />
        </HeroGridCard>
        <HeroGridCard className={`${styles["ukd__grid--section"]}`} style={{zIndex: "9001"}}>
          <HeroLayout className={`${styles["ukd__grid"]}`} imageOnLeft imageProps={{style: {position: "relative"}, alignItems: "flex-start", paddingTop: "42px"}}
            image={
              <div className={`${styles["home__understand-key-drivers-image"]}`}>
                <div className={`${styles["home__pattern-big"]}`} />
                <div className={`${styles["home__pattern-medium"]}`} />
                <Image src={HomeUnderstandKeyDrivers} />
              </div>
            }
            copy={
              <React.Fragment>
                <Heading fontWeight={600} style={{maxWidth: "100%", margin: "0", fontSize: "36px", lineHeight: "50px"}}>Understand key drivers</Heading>
                <Text my={3} style={{maxWidth: "600px", margin: "20px 0 0", fontSize: "17px", lineHeight: "32px", color: "#737B8B"}}>Gain visibility on key drivers of negative and positive emotions so that you can take appropriate action.</Text>
                <ul style={{display: "flex", flexDirection: "column", margin: "0", marginTop: "24px", padding: "0", listStyle: "none"}}>
                  <FancyListItem icon="purple">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Measure the impact of your wellbeing programs</Text>
                  </FancyListItem>
                  <FancyListItem icon="purple">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Allocate resources accordingly</Text>
                  </FancyListItem>
                  <FancyListItem icon="purple">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Inform policy decisions</Text>
                  </FancyListItem>
                </ul>
              </React.Fragment>
            }
          />
        </HeroGridCard>
        <HeroGridCard className={`${styles["ddwsv__grid--section"]}`} style={{zIndex: "9001"}}>
          <HeroLayout className={`${styles["ddwsv__grid"]}`} copyProps={{paddingTop: "0"}} imageProps={{style: {position: "relative"}, alignItems: "flex-start", paddingLeft: "135px"}}
            copy={
              <React.Fragment>
                <Heading fontWeight={600} style={{margin: "0", fontSize: "36px", lineHeight: "50px"}}>Dig deeper<br/>with student voice</Heading>
                <Text my={3} style={{maxWidth: "600px", margin: "20px 0 0", fontSize: "17px", lineHeight: "32px", color: "#737B8B"}}>We make it easy for student voices to be heard through simple filters and sophisticated alerts.</Text>
                <ul style={{display: "flex", flexDirection: "column", margin: "0", marginTop: "24px", padding: "0", listStyle: "none"}}>
                  <FancyListItem icon="green">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Have more meaningful conversations with your students</Text>
                  </FancyListItem>
                  <FancyListItem icon="green">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Urgency detection for high risk comments</Text>
                  </FancyListItem>
                  <FancyListItem icon="green">
                    <Text style={{fontSize: "17px", fontWeight: "600", lineHeight: "32px", letterSpacing: "0"}}>Useful filters and alerts</Text>
                  </FancyListItem>
                </ul>
              </React.Fragment>
            }
            image={
              <div className={`${styles["home__dig-deeper-with-student-voice-image"]}`}>
                <div className={`${styles["home__pattern-big"]}`} />
                <div className={`${styles["home__pattern-medium"]}`} />
                <div className={`${styles["home__pattern-small"]}`} />
                <div className={`${styles["home__pattern-white-1"]}`} />
                <div className={`${styles["home__pattern-white-2"]}`} />
                <div className={`${styles["home__pattern-white-3"]}`} />
                <Image src={HomeDigDeeperWithStudentVoice} />
              </div>
            }
          />
        </HeroGridCard>
        <HeroGridCard className={`${styles["hero--why-skodel"]}`} style={{maxWidth: "unset"}}>
          <Box className={`${styles["hero--why-skodel-stripes"]}`}>
            <Box className={`${styles["hero--why-skodel-stripes-inner"]}`}>
              <Box className={`${styles["hero--why-skodel-stripe-1"]}`}/>
              <Box className={`${styles["hero--why-skodel-stripe-2"]}`}/>
              <Box className={`${styles["hero--why-skodel-stripe-3"]}`}/>
              <Box className={`${styles["hero--why-skodel-stripe-4"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty style={{zIndex: "9001", paddingRight: "32px", paddingLeft: "32px"}} px={2}>
            <WidthMaxer alignItems="center">
              <Heading fontSize={5} color="white" textAlign="center">Why Skodel</Heading>
              <WhySkodelGrid>
                <WhySkodelBox icon={HomeSecurityIcon} heading="Less questions, more answers" text="Your time is precious. Our unique delivery model helps time poor schools uncover meaningful answers to complex wellbeing issues without adding to the workload. Don't just take our word for it though, try it out for yourself below." link="Try Now" linkColor="#A174FF"/>
                <WhySkodelBox icon={HomeTechnologyIcon} heading="Technology that works with your systems" text="An engineering and data analytics team experienced in collecting, cleaning and organising data. We tailor our implementation to work with your school systems, so there is no disruption to current processes." link="More Details" linkColor="#49B1E8"/>
                <WhySkodelBox icon={Home20YearsIcon} heading="Give every student a voice" text="We work with a range of communities, including Special Ed, juvenile detention centres and many others. Skodel is designed to give all students the opportunity to be heard and cared for. See how your students respond by checking-in with them now." link="Start a Check-in" linkColor="#FF7F81"/>
                <WhySkodelBox icon={HomeIntuitiveIcon} heading="More intuitive, more reliable" text="Our research has and will continue to be underpinned by a simple message ‘can we make the data more intuitive and more reliable’. We believe we’ve developed a best-in-class approach to wellbeing data collection and delivery of insights." link="More Details" linkColor="#DC6FE3"/>
              </WhySkodelGrid>
            </WidthMaxer>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard style={{zIndex: "3", marginTop: "235px", /* marginBottom: "160px"*/ }}>
          <HeroLayout className={`${styles["wellbeing__grid"]}`} style={{zIndex: "9001"}} copyProps={{className: `${styles["wellbeing__text"]}`}} imageProps={{style: {position: "relative"}, padding: 0}}
            copy={
              <Box style={{width: "100%", maxWidth: "628px"}}>
                <Heading fontWeight={600} style={{fontSize: "36px", lineHeight: "50px"}}>We run a wellbeing survey, How is Skodel different?</Heading>
                <Text fontSize={2} style={{marginTop: "24px", fontSize: "17px", lineHeight: "30px", color: "#737B8B"}}>Many of the school communities we work with run larger scale wellbeing surveys, what we offer is complementary to this. We are taking a different approach that seeks to make your wellbeing data more real time and intuitive.</Text>
                <BrandButton className={`${globalStyles["btn"]}`} to="/technology" mx={2} style={{marginTop: "48px", background: "#AA63FF", color: "#FFFFFF", boxShadow: "0 8px 26px 0 rgba(170, 99, 255, 0.30)", marginRight: "16px"}}>Find Out How</BrandButton>
              </Box>
            }
            image={
              <div className={`${styles["home__wellbeing--image"]}`}>
                <Image src={HomeWellbeingSurvey} />
              </div>
            }
          />
        </HeroGridCard>
        {/* <HeroGridCard style={{position: "relative", zIndex: "2"}}>
          <HeroLayoutEmpty style={{display: "flex", flexDirection: "column"}}>
            <Flex color="rgb(91, 157, 233)" mx="auto" bg="rgb(237, 245, 252)" style={{borderRadius: "20px", marginTop: "0", marginBottom: "17px", padding: "8px 20px"}}>
              <Heading fontSize={2}>Skodel Knowledge Centre</Heading>
            </Flex>
            <Heading fontWeight={600} textAlign="center" style={{width: "100%", maxWidth: "882px", margin: "0 auto", fontSize: "36px", lineHeight: "50px"}}>Key wellbeing issues, research &amp; more</Heading>
            <LatestResearchGrid>
                <LatestResearchBox imageUrl={HomeRetroOccupy} heading="Retro occupy organic, stumptown shabby chic" text="Synth polaroid bitters chillwave pickled. Vegan disrupt tousled, Portland keffiyeh aesthetic food truck sriracha cornh."/>
                <LatestResearchBox imageUrl={HomeKogiCosby} heading="Kogi Cosby sweater ethical squid irony disrupt, organic." text="Tousled food truck polaroid, salvia bespoke small batch Pinterest Marfa. Fingerstache authentic craft beer, food truck."/>
                <LatestResearchBox imageUrl={HomeFixieTote} heading="Fixie tote bag ethnic keytar. Neutra vinyl American Appar." text="Fixie tote bag ethnic keytar. Neutra vinyl American Apparel kale chips tofu art party, cardigan raw denim quinoa. Cray."/>
              </LatestResearchGrid>
          </HeroLayoutEmpty>
        </HeroGridCard> */}
        <HeroGridCard className={`${styles["hero--cta"]}`} color="white" justifyContent="center" alignItems="center" style={{maxWidth: "unset"}}>
          <Box className={`${styles["hero--cta-stripes"]}`}>
            <Box className={`${styles["hero--cta-stripes-inner"]}`}>
              <Box className={`${styles["hero--cta-stripe-1"]}`}/>
              <Box className={`${styles["hero--cta-stripe-2"]}`}/>
              <Box className={`${styles["hero--cta-stripe-3"]}`}/>
              <Box className={`${styles["hero--cta-stripe-4"]}`}/>
              <Box className={`${styles["hero--cta-stripe-5"]}`}/>
              {/* <Box className={`${styles["hero--cta-stripe-6"]}`}/> */}
              <Box className={`${styles["hero--cta-stripe-7"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty style={{zIndex: "9001", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "calc(100% - 60px)", maxWidth: "740px", margin: "0 30px -420px", padding: "40px", backgroundColor: "#fff", borderRadius: "15px", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
            <Heading style={{fontSize: "36px", lineHeight: "50px", textAlign: "center", color: "#232D42"}}>Every student, every voice</Heading>
            <Flex style={{marginTop: "32px"}}>
              <BrandButton className={`${globalStyles["btn"]}`} to="/contact-us" style={{background: "#AA63FF", color: "#FFFFFF", boxShadow: "0 8px 26px 0 rgba(170, 99, 255, 0.30)"}}>Book a meeting</BrandButton>
            </Flex>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <Footer></Footer>
      </HeroGrid>
    );
  }
}

export default Home;
