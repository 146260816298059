import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Heading, Box, Text, Flex, Button, Image } from "rebass";

import styles from "./Technology.module.css";
import homeStyles from './Home.module.css';
import globalStyles from "./Global.module.css";

import { PageSection, BrandButton, WidthMaxer } from "./shared";
import { HeroLayout, HeroLayoutEmpty } from "./HeroLayout";
import { HeroGrid, HeroGridCard } from "./HeroGrid";
import { FancyListItem } from "./Home";
import Footer from './Footer';

import logo from "./logo.svg";
import CollectIcon from "./technology-icon-collect.png";
import FacilitateIcon from "./technology-icon-facilitate.png";
import OrganiseIcon from "./technology-icon-organise.png";
import GetToTheBottomImage from "./technology-get-to-the-bottom.webp";
import TechnologyActionData from "./technology-action-data.png";
import TechnologyTransformingData from "./technology-transforming-data.webp";
import TechnologySchoolEvents from "./technology-school-events.png";
import TechnologyPhysiological from "./technology-physiological.png";
import TechnologyLMSAndSIS from "./technology-lms-and-sis.png";
import TechnologyAcademicPerformance from "./technology-academic-performance.png";
import TechnologyRealTimeIcon from "./technology-real-time-icon.png";
import TechnologyRetrospectiveIcon from "./technology-retrospective-icon.png";
import ListIcon from "./list-icon-2.png";

const securityPoints = [
  "All data is encrypted at all times",
  "Support for two-factor authentication",
  "SSO authentication protocols",
  "Role based access controls",
  "Alerts for suspicious behaviour"
];

class Technology extends Component {
  render() {
    return (
      <HeroGrid style={{position: "relative", overflow: "hidden", rowGap: "0"}}>
        <Helmet>
          <title>Skodel - Technology</title>
        </Helmet>
        <HeroGridCard className={`${styles["hero--technology"]}`} color="white" style={{maxWidth: "unset", padding: "0"}}>
          <Box className={`${styles["hero--technology-stripes"]} brand-stripes`}>
            <Box className={`${styles["hero--technology-stripes-inner"]}`}>
              <Box className={`${styles["hero--technology-stripe-1"]}`}/>
              <Box className={`${styles["hero--technology-stripe-2"]}`}/>
              <Box className={`${styles["hero--technology-stripe-3"]}`}/>
              <Box className={`${styles["hero--technology-stripe-4"]}`}/>
              <Box className={`${styles["hero--technology-stripe-5"]}`}/>
              <Box className={`${styles["hero--technology-stripe-6"]}`}/>
              <Box className={`${styles["hero--technology-stripe-7"]}`}/>
            </Box>
          </Box>
          <HeroLayout style={{zIndex: "9001", width: "100%", maxWidth: "1164px", margin: "200px auto 0"}} copyProps={{style: {display: "flex", justifyContent: "flex-start", alignItems: "flex-start"}}} imageProps={{style: {justifyContent: "flex-start", width: "670px", padding: "0"}}}
            copy={
              <Box className={`${styles["hero--technology__title"]}`} style={{display: "block", width: "100%", paddingTop: "40px"}}>
                <Heading style={{fontSize: "42px", lineHeight: "57px"}}>Transforming Data into Answers</Heading>
                <Text style={{margin: "15px 0 0", fontSize: "17px", lineHeight: "30px"}}>Make your wellbeing data intuitive and easy to use on a regular basis.</Text>
                <Box style={{margin: "60px 0 0"}}>
                  <BrandButton className={`${globalStyles["btn"]}`} mt={4} to="/contact-us" style={{background: "#FFFFFF", color: "#FF6E89"}}>Ask us how</BrandButton>
                </Box>
              </Box>
            }
            image={
              <Image src={TechnologyTransformingData}/>
            }
          />
        </HeroGridCard>
        <HeroGridCard className={`${styles["handling--technology"]}`}>
          <HeroLayoutEmpty>
            <Heading style={{fontSize: "36px", lineHeight: "50px", textAlign: "center"}}>Handling data end-to-end</Heading>
            <Box className={`${styles["handling--technology__grid"]}`} style={{display: "grid", marginTop: "80px"}}>
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <Image src={CollectIcon} height={"128px"} width={"128px"} />
                <Heading style={{marginTop: "32px", fontSize: "22px", lineHeight: "30px", textAlign: "center"}}>Collect</Heading>
                <Text style={{maxWidth: "280px", margin: "8px auto 0", fontSize: "16px", lineHeight: "26px", color: "#7b7b7b", textAlign: "center"}}>Simple collection tools and flexible API integration capabilities</Text>
              </Flex>
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <Image src={OrganiseIcon} height={"128px"} width={"128px"} />
                <Heading style={{marginTop: "32px", fontSize: "22px", lineHeight: "30px", textAlign: "center"}}>Organise</Heading>
                <Text style={{maxWidth: "280px", margin: "8px auto 0", fontSize: "16px", lineHeight: "26px", color: "#7b7b7b", textAlign: "center"}}>Sophisticated data models, purpose built to analyse student wellbeing</Text>
              </Flex>
              <Flex alignItems="center" justifyContent="center" flexDirection="column" m={4}>
                <Image src={FacilitateIcon} height={"128px"} width={"128px"} />
                <Heading style={{marginTop: "32px", fontSize: "22px", lineHeight: "30px", textAlign: "center"}}>Facilitate</Heading>
                <Text style={{maxWidth: "280px", margin: "8px auto 0", fontSize: "16px", lineHeight: "26px", color: "#7b7b7b", textAlign: "center"}}>Visualisations and alerts that simplify reporting and monitoring</Text>
              </Flex>
            </Box>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${styles["get-bottom--technology"]}`}>
          <Box className={`${styles["get-bottom--technology-stripes"]} brand-stripes`}>
            <Box className={`${styles["get-bottom--technology-stripes-inner"]}`}>
              <Box className={`${styles["get-bottom--technology-stripe-1"]}`}/>
              <Box className={`${styles["get-bottom--technology-stripe-2"]}`}/>
              <Box className={`${styles["get-bottom--technology-stripe-3"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty className={`${styles["get-bottom--technology__content"]}`}>
            <Heading style={{fontSize: "36px", lineHeight: "50px", textAlign: "center"}}>Get to the bottom of it by</Heading>
            <HeroLayout imageOnLeft style={{marginTop: "60px", padding: "0"}} imageProps={{style: {display: "flex", justifyContent: "center", alignItems: "flex-start", padding: "0"}}} copyProps={{style: {display: "flex", justifyContent: "center", alignItems: "flex-start"}}}
              copy={
                <Box className={`${styles["get-bottom--technology__text"]}`} style={{marginTop: "50px"}}>
                  <Heading style={{fontSize: "22px", lineHeight: "30px"}}>Bringing more context into your wellbeing data</Heading>
                  <Text style={{marginTop: "32px", fontSize: "16px", lineHeight: "26px", color: "#7B7B7B"}}>Our flexible API eliminates data silos by tying objective and subjective data from various sources together, forming a holistic picture of student wellbeing. It also enables event based delivery of check-ins, which provides insight into how children respond to real world scenarios as they happen.</Text>
                </Box>
              }
              image={
                <Image src={GetToTheBottomImage} />
              }
              />
            <Flex className={`${styles["get-bottom--technology__grid"]}`} style={{justifyContent: "space-between", display: "grid", marginTop: "90px"}}>
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <Image src={TechnologyAcademicPerformance} height={"128px"} width={"128px"} style={{maxWidth: "100%", height: "auto"}}/>
                <Heading style={{marginTop: "28px", fontSize: "16px", lineHeight: "22px"}} textAlign="center">Academic Performance</Heading>
              </Flex>
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <Image src={TechnologyLMSAndSIS} height={"128px"} width={"128px"} style={{maxWidth: "100%", height: "auto"}}/>
                <Heading style={{marginTop: "28px", fontSize: "16px", lineHeight: "22px"}} textAlign="center">LMS &amp; SIS</Heading>
              </Flex>
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <Image src={TechnologyPhysiological} height={"128px"} width={"128px"} style={{maxWidth: "100%", height: "auto"}}/>
                <Heading style={{marginTop: "28px", fontSize: "16px", lineHeight: "22px"}} textAlign="center">Physiological</Heading>
              </Flex>
              <Flex alignItems="center" justifyContent="center" flexDirection="column">
                <Image src={TechnologySchoolEvents} height={"128px"} width={"128px"} style={{maxWidth: "100%", height: "auto"}}/>
                <Heading style={{marginTop: "28px", fontSize: "16px", lineHeight: "22px"}} textAlign="center">School events &amp; initiatives</Heading>
              </Flex>
            </Flex>
            <Box style={{maxWidth: "686px", margin: "0 auto"}}>
              <Heading textAlign="center" style={{marginTop: "120px", fontSize: "22px", lineHeight: "30px"}}>And exploring more in less time with 'Skodel Tags'</Heading>
              <Text textAlign="center" style={{marginTop: "48px", fontSize: "16px", lineHeight: "26px", color: "#7B7B7B"}}>'Skodel Tags' unifies data for defined wellbeing domains within your school so you can rapidly examine the performance of your school in the areas that matter most. This allows you to deeply understand the impact of your initiatives and uncover key insights that can shape policy decision making at the school.</Text>
            </Box>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${styles["security--technology"]}`}>
          <Box className={`${styles["security--technology-stripes"]} brand-stripes`}>
            <Box className={`${styles["security--technology-stripes-inner"]}`}>
              <Box className={`${styles["security--technology-stripe-1"]}`}/>
              <Box className={`${styles["security--technology-stripe-2"]}`}/>
              <Box className={`${styles["security--technology-stripe-3"]}`}/>
              <Box className={`${styles["security--technology-stripe-4"]}`}/>
              <Box className={`${styles["security--technology-stripe-5"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty className={`${styles["security--technology__content"]}`}>
            <Heading className={`${styles["security--technology__content-title"]}`} textAlign="center">Security is Top of Mind</Heading>
            <Text className={`${styles["security--technology__content-subtitle"]}`} textAlign="center">Our development team has a strong security background and our software has been independently assessed by leaders in cyber security.</Text>
            <Box className={`${styles["security--technology__content-grid"]}`} style={{display: "grid", gridGap: "24px"}}>
              {securityPoints.map(( securityPoint ) => (
                <Box style={{padding: "24px 32px", backgroundColor: "#ffffff", borderRadius: "10px", boxShadow: "0 42px 84px 0 rgba(36, 43, 76, .07)"}}>
                  <Heading fontSize={2} style={{ display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                    <Text>{securityPoint}</Text>
                    <Box style={{width: "40px", height: "40px", padding: "8px", borderRadius: "50%", backgroundColor: "#ffffff", boxShadow: "0 4px 8px 0 rgba(143, 174, 225, .29)"}}>
                      <Image src={ListIcon} width="24" height="24px"/>
                    </Box>
                  </Heading>
                </Box>
              ))}
            </Box>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${styles["action--technology"]}`}>
          <HeroLayoutEmpty >
            <Heading className={`${styles["action--technology-title"]}`}>It’s never been this easy to action data</Heading>
          </HeroLayoutEmpty>
          <HeroLayout
            copy={
              <Box className={`${styles["action--technology-grid"]}`}>
                <Box className={`${styles["action--technology-box"]}`} style={{boxShadow: "0 42px 84px 0 rgba(36, 43, 75, .07)", flexDirection: "row", display: "flex"}}>
                  <Image src={TechnologyRealTimeIcon} style={{width: "auto", maxWidth: "64px", maxHeight: "64px", height: "100%"}} mr={4}/>{" "}
                  <Box>
                    <Heading fontSize={3}>Real time</Heading>
                    <Text my={2}>An alert system that pushes notifications via your school’s main communication channels to inform early interventions</Text>
                  </Box>
                </Box>
                <Box className={`${styles["action--technology-box"]}`} style={{boxShadow: "0 42px 84px 0 rgba(36, 43, 75, .07)", flexDirection: "row", display: "flex"}}>
                  <Image src={TechnologyRetrospectiveIcon} style={{width: "auto", maxWidth: "64px", maxHeight: "64px", height: "100%"}} mr={4}/>
                  <Box>
                    <Heading fontSize={3}>Retrospective</Heading>
                    <Text my={2}>Dashboards that transform big data into digestible visualisations to help your wellbeing leaders make informed decisions</Text>
                  </Box>
                </Box>
              </Box>
            }
            image={
              <Box className={`${styles["action--technology--image"]}`} style={{width: "100%", maxWidth: "524px"}}>
                <Image src={TechnologyActionData} />
              </Box>
            }
          />
        </HeroGridCard>
        <HeroGridCard className={`${homeStyles["hero--cta"]}`} color="white" justifyContent="center" alignItems="center" style={{maxWidth: "unset"}}>
          <Box className={`${homeStyles["hero--cta-stripes"]}`}>
            <Box className={`${homeStyles["hero--cta-stripes-inner"]}`}>
              <Box className={`${homeStyles["hero--cta-stripe-1"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-2"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-3"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-4"]}`}/>
              <Box className={`${homeStyles["hero--cta-stripe-5"]}`}/>
              {/* <Box className={`${homeStyles["hero--cta-stripe-6"]}`}/> */}
              <Box className={`${homeStyles["hero--cta-stripe-7"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty style={{zIndex: "9001", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "calc(100% - 60px)", maxWidth: "740px", margin: "0 30px -420px", padding: "40px", backgroundColor: "#fff", borderRadius: "15px", boxShadow: "0 22px 60px 0 rgba(24, 50, 92, .1)"}}>
            <Heading style={{fontSize: "36px", lineHeight: "50px", textAlign: "center", color: "#232D42", maxWidth: "508px"}}>Check out the Skodel tech for yourself.</Heading>
            <Flex style={{marginTop: "32px"}}>
              <BrandButton className={`${globalStyles["btn"]}`} to="/contact-us" style={{background: "#AA63FF", color: "#FFFFFF", boxShadow: "0 8px 26px 0 rgba(170, 99, 255, 0.30)"}}>Contact Us</BrandButton>
            </Flex>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <Footer></Footer>
      </HeroGrid>
    );
  }
}

export default Technology;