import React, { Component } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { Heading, Box, Text, Flex, Button, Image } from "rebass";
import Masonry from 'react-masonry-css';

import styles from "./AboutUs.module.css";
import globalStyles from "./Global.module.css";

import { PageSection, WidthMaxer, BrandButton } from "./shared";
import { HeroGridCard, HeroGrid } from "./HeroGrid";
import { HeroLayoutEmpty } from "./HeroLayout";
import Footer from './Footer';

import logo from "./logo.svg";
import AboutUsHistory1 from "./about-us-history-1.webp";
import AboutUsHistory2 from "./about-us-history-2.webp";
import buildingImage from "./building.png";

import christopherTan from "./christopher-tan.jpg";
import timothyKelleher from "./timothy-kelleher.jpg";
import oliviaAppleton from "./olivia-appleton.jpg";


const Profile = ({ imageUrl, name, bio, legal, ...otherProps }) => (
  <Box key="name" style={{padding: "36px", backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0 42px 84px 0 rgba(36, 43, 75, 0.10)"}}{...otherProps}>
    <Flex justifyContent="flex-start" alignItems="center">
      <Image backgroundColor="#eeeeee" src={imageUrl} style={{width: "68px", height: "68px", borderRadius: "9px"}}/>
      <Flex flexDirection="column" style={{marginLeft: "20px"}}>
        <Heading style={{fontSize: "22px", lineHeight: "30px"}}>{name}</Heading>
        <Heading style={{fontSize: "14px", lineHeight: "26px", color: "rgb(115, 123, 138)"}}>{ legal }</Heading>
      </Flex>
    </Flex>
    <Flex flexDirection="column" style={{marginTop: "16px", fontSize: "14px", lineHeight: "24px", color: "#7b7b7b"}}>
      <Text>{bio}</Text>
    </Flex>
  </Box>
);

const allProfiles = [
  {
    imageUrl: "/images/about-us-portraits/image32.png",
    name: "Peter O'Connell",
    bio: "Co-founder of ASX-listed Australian telco amaysim and Co-Founder and Chairman of The Chic Group. Peter has served on a number of boards for public companies as well as government bodies. Peter supports Skodel through his extensive network and strategic advice.",
    legal: "Leadership Team"
  },
  {
    imageUrl: "/images/about-us-portraits/image13.jpg",
    name: "Julian Fagan",
    bio: "Co-founder and director of education company, HSC Study Buddy, before exiting the business via trade sale. Julian then became Head of Partnerships for Telstra treats, a rewards application supported by Telstra and was responsible for acquiring and advising top tier businesses using the platform.",
    legal: "Leadership Team"
  },
  {
    imageUrl: "/images/about-us-portraits/image3.jpg",
    name: "Kevin Hardy",
    bio: "Founder and Director of Business Development at HardyGroup, executive health and human services recruitment. Kevin has established the most highly regarded and successful Executive Learning Set network of health and public-sector leaders throughout the Asia Pacific region. Kevin supports Skodel through access to this network for research purposes.",
    legal: "Leadership Team"
  },
  {
    imageUrl: "/images/about-us-portraits/image25.jpg",
    name: "Liz Hlipala",
    bio: "Liz Hlipala has a thirty-year career working in health at the highest levels in human resource, organisational development and workforce. Liz supports Skodel by connecting Skodel with leading health organisations specialising in child development and mental health.",
    legal: "Leadership Team"
  },
  {
    imageUrl: "/images/about-us-portraits/image22.jpg",
    name: "Ian Fagan",
    bio: "Co-founder and director of education company, HSC Study Buddy, before exiting the business via trade sale. Ian has consulted across a wide range of industries, with a focus on translating research into usable technologies.",
    legal: "Leadership Team"
  },
  {
    imageUrl: null,
    name: "Huon Peard",
    bio: "Huon Peard is a full stack developer, with a strong security background. He led development on education company, HSC Study Buddy, which was acquired in a trade sale. Huon has consulted for large government bodies as well as multi-national companies, in the security and web application areas.",
    legal: "Technology/Product Team"
  },
  {
    imageUrl: "/images/about-us-portraits/image17.jpg",
    name: "Alan Sammarone",
    bio: "Software engineer with 10+ years experience across multiple sectors, including education. He has worked at large organisations and early stage start-ups. Alan specialises in API infrastructure, machine learning models and developing/maintaining high performance systems.",
    legal: "Technology/Product Team"
  },
  {
    imageUrl: "/images/about-us-portraits/image39.jpg",
    name: "Shubham Shah",
    bio: "Started his career as a security consultant at Ernst & Young before becoming a Senior Security Analyst at age 19 at Bishop Fox, a global leader in Cyber Security. Shubham specialises in application security assessment, source code review, and mobile application security.",
    legal: "Technology/Product Team"
  },
  {
    imageUrl: "/images/about-us-portraits/image11.png",
    name: "Lily Laurankova",
    bio: "8 years experience in design and UX, much of which was spent working at one of Europe’s largest graphic design agencies, Fireart. She has designed for large brand names, including Atlassian, Google and many more. Lily has built a strong reputation for designing modern UIs that are simple to use.",
    legal: "Technology/Product Team"
  },
  {
    imageUrl: "/images/about-us-portraits/image37.jpg",
    name: "Maggie Boutrous",
    bio: "Maggie has a bachelor of Medical Science and a Doctorate in Law through the University of Technology, Sydney. Maggie has over 4 years worth of experience practicing law as a solicitor at Carroll & O’Dea Lawyers as well as Legal Counsel at Coates Hire.",
    legal: "Legal Team"
  },
  {
    name: "Crispin Jenkinson",
    imageUrl: "/images/about-us-portraits/image.jpg",
    bio: "Crispin Jenkinson is Professor of Health Services Research, Director of the Health Services Research Unit (University of Oxford), and a Senior Research Fellow of Harris Manchester College. Crispin specialises in patient reported outcomes and health status measurement, the evaluation of patient medical care, and methodology. He has over 150 peer reviewed papers and extensive experience in developing and validating outcome measures, including global recognition for the most widely adopted quality of life measure for the carers of people with Parkinson's disease.",
    legal: "Research Team"
  },
  {
    name: "David Churchman",
    imageUrl: "/images/about-us-portraits/David-Churchman.jpg",
    bio: "David is an Associate of the Health Services Research Unit (HSRU) at the University of Oxford. He brings a wealth of experience with him in the transformation of research into scalable technology, including securing investment for two spin-out companies for Oxford University Innovation. David obtained a PhD and MSc from the University of Surrey and is highly regarded for his work in developing high-tech scientific products.",
    legal: "Research Team"
  }
];

const jobs = [
  { title: "Account Manager" },
  { title: "Customer Success" },
  { title: "Data Scientist" },
  { title: "Sales" }
];

const breakpointColumnsObj = {
  default: 3,
  1200: 2,
  768: 1
};

class AboutUs extends Component {
  render() {
    return (
      <HeroGrid style={{position: "relative", overflow: "hidden", rowGap: "0"}}>
        <Helmet>
          <title>Skodel - About</title>
        </Helmet>
        <PageSection className={`${styles["hero--about-us"]}`} color="white">
          <Box className={`${styles["hero--about-us-stripes"]} brand-stripes`}>
            <Box className={`${styles["hero--about-us-stripes-inner"]}`}>
              <Box className={`${styles["hero--about-us-stripe-1"]}`}/>
              <Box className={`${styles["hero--about-us-stripe-2"]}`}/>
              <Box className={`${styles["hero--about-us-stripe-3"]}`}/>
              <Box className={`${styles["hero--about-us-stripe-4"]}`}/>
              <Box className={`${styles["hero--about-us-stripe-5"]}`}/>
              <Box className={`${styles["hero--about-us-stripe-6"]}`}/>
              <Box className={`${styles["hero--about-us-stripe-7"]}`}/>
            </Box>
          </Box>
          <HeroGridCard style={{margin: "208px auto 0"}}>
            <HeroLayoutEmpty>
              <Flex justifyContent="center">
                <Heading fontSize={2} color="rgb(91, 157, 233)" justifyContent="center" style={{display: "flex", borderRadius: "20px"}} p={2} px={3} bg="rgba(70, 156, 240, 0.1)">About Us</Heading>
              </Flex>
              <Heading style={{maxWidth: "744px", margin: "10px auto 0", fontSize: "42px", lineHeight: "58px", color: "#222A41", textAlign: "center"}}>Empowering schools to build a strong community of care</Heading>
              <Text style={{maxWidth: "716px", margin: "24px auto 0", fontSize: "17px", lineHeight: "30px", color: "#7b7b7b", textAlign: "center"}}>Based in Sydney, Australia, we are a group of individuals with diverse experience, from across the globe, brought together through a common mission: to extend the impact of education.</Text>
              <Box bg="white" style={{margin: "88px 0 0", padding: "40px", color: "#737B8B", borderRadius: "10px", boxShadow: "0 42px 84px 0 rgba(36, 43, 75, 0.15)"}}>
                <Box className={`${styles["hero--about-us__grid"]}`} style={{display: "grid"}}>
                  <Text>
                    <Image src={AboutUsHistory1} style={{height: "auto", maxWidth: "100%"}}/>
                  </Text>
                  <Text>
                    <Image src={AboutUsHistory2} style={{height: "auto", maxWidth: "100%"}}/>
                  </Text>
                </Box>
                <Heading style={{marginTop: "32px", fontSize: "22px", lineHeight: "30px", color: "#222A41"}}>Our History</Heading>
                <Box className={`${styles["hero--about-us__grid"]}`} style={{display: "grid"}}>
                  <Text style={{marginTop: "16px", fontSize: "16px", lineHeight: "26px", color: "#7b7b7b"}}>The goal of education should be to help each child discover their meaning and go on to lead healthy lives, contributing positively to their community. There is enough evidence to suggest that a strict focus on academic performance does not achieve this. We therefore cannot afford to continue to operate under the same model. We must educate for the future.</Text>
                  <Text style={{marginTop: "16px", fontSize: "16px", lineHeight: "26px", color: "#7b7b7b"}}>Schools are in a unique position as they teach and support children in a variety of different settings. Whether it be learning, sporting, or social, these interactions offer a chance to make a positive impact on that childs life. We believe that when schools are unified in their definition and understanding of wellbeing and have reliable systems in palce to measure and monitor this, the impact will be greater</Text>
                </Box>
              </Box>
            </HeroLayoutEmpty>
          </HeroGridCard>
        </PageSection>
        <HeroGridCard className={`${styles["team--about-us"]}`}>
          <Box className={`${styles["team--about-us-stripes"]}`}>
            <Box className={`${styles["team--about-us-stripes-inner"]}`}>
              <Box className={`${styles["team--about-us-stripe-1"]}`}/>
              <Box className={`${styles["team--about-us-stripe-2"]}`}/>
              <Box className={`${styles["team--about-us-stripe-3"]}`}/>
              <Box className={`${styles["team--about-us-stripe-4"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty className={`${styles["team--about-us__content"]}`}>
            <Heading style={{fontSize: "36px", lineHeight: "50px", color: "#222A41", textAlign: "center"}}>Our Team</Heading>
            <Flex className={`${styles["team--about-us__grid"]}`} flexDirection="row" flexWrap="wrap" style={{display: "grid", gridGap: "24px", marginTop: "40px"}}>
              <Masonry breakpointCols={breakpointColumnsObj} className={`${styles["my-masonry-grid"]}`} columnClassName={`${styles["my-masonry-grid_column"]}`}>
                {allProfiles.map((profile, profileIdx) => (
                  <Profile  key={"team" + profileIdx} {...profile} />
                ))}
              </Masonry>
            </Flex>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${styles["building--about"]}`}>
          <Box className={`${styles["building--about-stripes"]} brand-stripes`}>
            <Box className={`${styles["building--about-stripes-inner"]}`}>
              <Box className={`${styles["building--about-stripe-1"]}`}/>
              <Box className={`${styles["building--about-stripe-2"]}`}/>
              <Box className={`${styles["building--about-stripe-3"]}`}/>
              <Box className={`${styles["building--about-stripe-4"]}`}/>
              <Box className={`${styles["building--about-stripe-5"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty className={`${styles["building--about-content"]}`}>
            <Heading style={{maxWidth: "850px", margin: "0 auto", fontSize: "36px", lineHeight: "50px", textAlign: "center", color: "#ffffff"}}>Ten years building products in education, with a strong track record of delivering outcomes.</Heading>
            <Box className={`${styles["building--about-grid"]}`} style={{display: "grid", gridGap: "32px", marginTop: "80px"}}>
              <Flex justifyContent="space-between" style={{padding: "32px", boxShadow: "rgba(36, 43, 75, 0.07) 0px 42px 84px 0px", background: "#FFFFFF", color: "#737B8B", borderRadius: "8px"}} flexDirection="column">
                <Text style={{fontSize: "14px", lineHeight: "24px", color: "#7b7b7b"}}>"Great educational concepts often go unused as the design just doesn’t fit within the school. Skodel understands the day to day operations of schools and the execution of their product reflects that.”</Text>
                <Box style={{display: "flex", alignItems: "center", marginTop: "15px"}}>
                  <Image src={christopherTan} style={{width: "40px", borderRadius: "50%"}}/>
                  <Heading style={{marginLeft: "16px", color: "#222A41", fontSize: "17px", lineHeight: "30px"}}>Christopher Tan</Heading>
                </Box>
              </Flex>
              <Flex flexDirection="column" justifyContent="space-between" style={{padding: "32px", boxShadow: "rgba(36, 43, 75, 0.07) 0px 42px 84px 0px", background: "#FFFFFF", color: "#737B8B", borderRadius: "8px"}}>
                <Text style={{fontSize: "14px", lineHeight: "24px", color: "#7b7b7b"}}>"The ability to effectively communicate data to different educational stakeholders is hard. The Skodel team does it very well.”</Text>
                <Box style={{display: "flex", alignItems: "center", marginTop: "15px"}}>
                  <Image src={timothyKelleher} style={{width: "40px", borderRadius: "50%"}}/>
                  <Heading style={{marginLeft: "16px", color: "#222A41", fontSize: "17px", lineHeight: "30px"}}>Timothy Kelleher</Heading>
                </Box>
              </Flex>
              <Flex flexDirection="column" justifyContent="space-between" style={{padding: "32px", boxShadow: "rgba(36, 43, 75, 0.07) 0px 42px 84px 0px", background: "#FFFFFF", color: "#737B8B", borderRadius: "8px"}}>
                <Text style={{fontSize: "14px", lineHeight: "24px", color: "#7b7b7b"}}>"It was refreshing to be involved in the design process as a teacher and seeing the team make changes based on the feedback we provided as teachers.”</Text>
                <Box style={{display: "flex", alignItems: "center", marginTop: "15px"}}>
                  <Image src={oliviaAppleton} style={{width: "40px", borderRadius: "50%"}}/>
                  <Heading style={{marginLeft: "16px", color: "#222A41", fontSize: "17px", lineHeight: "30px"}}>Olivia Appleton</Heading>
                </Box>
              </Flex>
            </Box>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard style={{minHeight: "unset", position: "relative", zIndex: "2"}}>
          <HeroLayoutEmpty>
            <Heading style={{fontSize: "36px", lineHeight: "50px", textAlign: "center", color: "#ffffff"}}>Investors</Heading>
            <Box className={`${styles["investor--about__grid"]}`} style={{display: "grid", gridGap: "32px", margin: "32px auto 0"}}>
              <Flex justifyContent="center" alignItems="stretch">
                <Flex justifyContent="center" alignItems="center" bg="white" width="100%" height="100%" style={{padding: "25px 0", borderRadius: "8px", boxShadow: "0 42px 84px 0 rgba(36, 43, 75, .07)"}}>
                  <Image src="/images/nsw-government.png" alt="New South Wales Government" style={{maxHeight: "86px", maxWidth: "86px"}}/>
                </Flex>
              </Flex>
              <Flex justifyContent="center" alignItems="stretch">
                <Flex justifyContent="center" alignItems="center" bg="white" width="100%" style={{padding: "25px 0", borderRadius: "8px", boxShadow: "0 42px 84px 0 rgba(36, 43, 75, .07)"}}>
                  <Image src="/images/uts.png" alt="University of Technology, Sydney" style={{maxHeight: "86px", maxWidth: "86px"}}/>
                </Flex>
              </Flex>
              <Flex justifyContent="center" alignItems="stretch">
                <Flex justifyContent="center" alignItems="center" bg="white" width="100%" style={{padding: "25px 0", borderRadius: "8px", boxShadow: "0 42px 84px 0 rgba(36, 43, 75, .07)"}}>
                  <Image src="/images/about-us-portraits/image32.png" alt="Peter O'Connell" style={{maxHeight: "86px", maxWidth: "86px", borderRadius: "50%"}}/>
                </Flex>
              </Flex>
              <Flex justifyContent="center" alignItems="stretch">
                <Flex justifyContent="center" alignItems="center" bg="white" width="100%" style={{padding: "25px 0", borderRadius: "8px", boxShadow: "0 42px 84px 0 rgba(36, 43, 75, .07)"}}>
                  <Image src="/images/about-us-portraits/image3.jpg" alt="Kevin Hardy" style={{maxHeight: "86px", maxWidth: "86px", borderRadius: "50%"}}/>
                </Flex>
              </Flex>
            </Box>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <HeroGridCard className={`${styles["join-team--about"]}`}>
          <Box className={`${styles["join-team--about-stripes"]} brand-stripes`}>
            <Box className={`${styles["join-team--about-stripes-inner"]}`}>
              <Box className={`${styles["join-team--about-stripe-1"]}`}/>
              <Box className={`${styles["join-team--about-stripe-2"]}`}/>
              <Box className={`${styles["join-team--about-stripe-3"]}`}/>
              <Box className={`${styles["join-team--about-stripe-4"]}`}/>
              <Box className={`${styles["join-team--about-stripe-5"]}`}/>
              <Box className={`${styles["join-team--about-stripe-6"]}`}/>
              <Box className={`${styles["join-team--about-stripe-7"]}`}/>
              <Box className={`${styles["join-team--about-stripe-8"]}`}/>
            </Box>
          </Box>
          <HeroLayoutEmpty className={`${styles["join-team--about__content"]}`}>
            <Heading fontSize={5} mt={4} mb={3} textAlign="center">Join the team!</Heading>
            <Text style={{maxWidth: "520px", margin: "9px auto 0", color: "#737B8B", fontSize: "14px", lineHeight: "24px", textAlign: "center"}}>If you share our vision and want to join a great team, then enquire about our job availabilities below.</Text>
            <Box className={`${styles["join-team--about__grid"]}`} style={{display: "grid", gridGap: "32px", marginTop: "65px"}}>
              {jobs.map((job, jobIdx) => (
                <Flex className={`${styles["join-team--about__list"]}`} style={{backgroundColor: "#FFFFFF", borderRadius: "8px", boxShadow: "0 42px 84px 0 rgba(36, 43, 75, 0.07)"}}>
                  <Heading style={{display: "flex", alignItems: "center", justifyContent: "center", fontSize: "22px", lineHeight: "30px"}}>{job.title}</Heading>
                  <BrandButton className={`${globalStyles["btn"]}`} pl={3} fontWeight={300} fontSize={2} style={{background: "#AA63FF", color: "#FFFFFF"}} to="/contact-us">Enquire about position</BrandButton>
                </Flex>
              ))}
            </Box>
          </HeroLayoutEmpty>
        </HeroGridCard>
        <Footer></Footer>
      </HeroGrid>
    );
  }
}

export default AboutUs;